import React, { useEffect, useRef, useState } from 'react';
import 'react-activity/dist/Spinner.css';
import { CameraButton, CloseButton, ModalBlock, ModalBody, ModalContainer} from './styled';
import CloseIcon from '@mui/icons-material/Close';
import Webcam from 'react-webcam';
import { Cameraswitch, RadioButtonChecked } from '@mui/icons-material';

interface ModalPictureProps {
    isActive: boolean;
    getResult: Function;
    onClose: Function;

}

export default function ModalPicture(props: ModalPictureProps) {
    const webcamRef = useRef(null);
    const [dimension, setDimension] = useState<{width: number, height: number}>()
    const [camera, setCamera] = useState<string>('environment')

    useEffect(() => {
        setDimension({
            width: window.innerWidth,
            height: window.innerHeight
        })
    },[])

    const capture = React.useCallback(
        async () => {
          const imageSrc = webcamRef.current.getScreenshot();
          props.getResult(imageSrc)
        },
        [webcamRef]
    );

    return (
        <>
            {props.isActive && 
                <ModalContainer>
                    <CloseButton>
                        <CloseIcon onClick={() => props.onClose()}/>
                    </CloseButton>
                    <Webcam
                        ref={webcamRef}
                        audio={false}
                        height={dimension?.height*0.7}
                        screenshotFormat="image/jpeg"
                        width={dimension?.width*0.7}
                        videoConstraints={{
                            width: dimension?.width*0.7,
                            height: dimension?.height*0.7,
                            facingMode: camera
                        }}
                    />
                    <CameraButton onClick={() => setCamera(camera === 'environment'? 'user' : 'environment')}>
                        <Cameraswitch />
                    </CameraButton>
                    <CameraButton onClick={capture}>
                        <RadioButtonChecked />
                    </CameraButton>       
                </ModalContainer>
            }
        </>
    );
}
