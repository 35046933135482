import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import { Box, BoxesContainer, Title } from './styled';

import Totem_ConfigurandoEvento from './docs/1_Totem_ConfigurandoEvento.docx';
import Totem_ConfigurandoImpressora from './docs/2_Totem_ConfigurandoImpressora.docx';

import { Step } from './Step';

export function Instalacao() {
    return (
        <Background>
            <Header pageTitle='Manual de Instalação' />
            <BoxesContainer>
                <Box>
                    <Title> Utilizando Totem </Title>
                    <Step
                        content={
                            <a
                                href={Totem_ConfigurandoEvento}
                                download={'1_Totem_ConfigurandoEvento'}
                                target={'_blank'}
                            >
                                1. Configurando Evento
                            </a>
                        }
                    />
                    <Step
                        content={
                            <a
                                href={Totem_ConfigurandoImpressora}
                                download={'2_Totem_ConfigurandoImpressora'}
                                target={'_blank'}
                            >
                                2. Configurando Impressora
                            </a>
                        }
                    />
                </Box>
            </BoxesContainer>
        </Background>
    );
}
