import { Background } from '@/Components/Background'
import { Header } from '@/Components/Header'
import ModalLoading from '@/Components/ModalLoading'
import React, { useEffect, useRef, useState } from 'react'
import { CheckInContainer, DataContainer, Row, Title, WhiteBoxContainer } from './styled'
import { Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import ApiService from '@/Services/api/ApiService'
import { Attraction, AttractionParticipantCheckIn } from '@/Services/api/models/attractionsModels'
import { toast } from 'react-toastify'
import { GetParticipantResponse } from '@/Services/api/models/participantsModel'
import { TButton } from '@/Components/TButton'

export default function AttractionCheckIn() {
  const input = useRef(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [participants, setParticipants] = useState<GetParticipantResponse[]>()
  const [idOrDoc, setIdOrDoc] = useState<string>()
  const { eventID, attractionID } = useParams()
  const [eventId, setEventId] = useState<string>()
  const [attractionId, setAttractionId] = useState<string>()

  const getEventId = async () => {
    if (eventID) {
        setEventId(eventID)
        setAttractionId(attractionID)
    }
    else {
        const userInfo = await JSON.parse(localStorage.getItem('userInfo'))
        const eventId = userInfo?.events?.[0]
        const attractions:Attraction[] = await ApiService.attractionFindByEvent(eventId)
        const attraction = attractions.find((attraction) => attraction.receptionUserId === userInfo?.id)
        setEventId(eventId)
        setAttractionId(attraction?.id?.toString())
    }
  }

  const handleGetParticipants = async () => {
    setIsLoading(true)
    try {
      const data = await ApiService.getAllParticipantsByEvent(Number(eventId))
      setParticipants(data)
    }
    catch (e: any) {
      toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
    }
    finally{
        setIsLoading(false)
    }
  }

  const findParticipant = () => {
    const participantFound = participants?.find(participant => participant.id === Number(idOrDoc))?.id
    if(participantFound) {
      handleCheckIn(participantFound)
    }
    else {
      const participantFoundByDoc = participants?.find(participant => participant.documentNumber === idOrDoc)?.id
      if (participantFoundByDoc) {
        handleCheckIn(participantFoundByDoc)
      }
      else {
        toast.error('Participante não encontrado')
      }
    }

  }
  
  const handleCheckIn = async (participantId: number) => {
    setIsLoading(true)
    try {
      const checkInRequest:AttractionParticipantCheckIn = {
        id: null,
        attractionId: Number(attractionId),
        attractionParticipantCheckInType: 'CHECK_IN',
        participantId: participantId,
        insertDatetime: null
      }
      const response = await ApiService.attractionParticipantCheckIn(checkInRequest)
      toast.success('Check-in realizado com sucesso')
    }
    catch (e: any) {
      toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
    }
    finally{
        setIsLoading(false)
        if(input) {
          input?.current?.focus()
        }
    }

  }

  useEffect(() => {
    if(eventId) {
      handleGetParticipants()
    }
  },[eventId])

  useEffect(() => {
    getEventId()
  },[])

  return (
    <Background>
      <ModalLoading isActive={isLoading} />
      <Header pageTitle='Capture Control - Check-in' />
      <WhiteBoxContainer>
        <CheckInContainer>
          <Form.Control
            type='text'
            autoFocus
            placeholder='ID ou Documento do Participante'
            className='addField'
            onChange={(e) => setIdOrDoc(e.target.value)}
            ref={input}
          />
        </CheckInContainer>
        <DataContainer>
          <TButton onClick={findParticipant}>
            Check-in
          </TButton>
        </DataContainer>
      </WhiteBoxContainer>
    </Background>
  )
}
