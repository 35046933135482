import { TButton } from '@/Components/TButton';
import styled from 'styled-components';

export const ConfigureLabelContainer = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;

    img {
        width: 350px;
        height: 180px;
    }
`;

export const LabelRow = styled.div`
    display: grid;
    grid-template-columns: 50% 25% 25%;
    padding: 2%
`

export const LabelButton = styled(TButton)`
    margin-bottom: 50px;
`;

export const BoxWithTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 25px;
    width: 100%
`;

export const Title = styled.h1`
    font-size: ${({ theme }) => theme.fontSize['3xl']};
    font-weight: bold;
    margin-bottom: 20px;
`;

export const Subtitle = styled.p`
    font-weight: bold;
`

export const ResulutionContainer = styled.div``;
