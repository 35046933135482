import ModalLoading from '@/Components/ModalLoading';
import { useToken } from '@/Components/UseToken';
import ApiService from '@/Services/api/ApiService';
import { EventResponse } from '@/Services/api/models/eventModel';
import {
    CreateParticipantRequest,
    GetParticipantResponse,
} from '@/Services/api/models/participantsModel';
import { handlePrintTicket } from '@/util/print';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Column, GambetaContainer, SignInParticipantContainer, WarnText } from './styled';
import * as yup from 'yup';
import { DashedHR, SubmitButton } from '@/Pages/Events/Adicionar/styled';

import EstadosBrasileiros from '@/util/estadosBrasileiros.json';
import { TButton } from '@/Components/TButton';
import { BackArrow } from '@/Components/ArrowBack';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { useAutoAttendantNavigate } from '@/Components/CustomNavigate/autoattendant';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import { TranslateParticipantFields } from '@/util/translate';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { ErrorMessage } from '../CheckIn/styled';
import { DefaultResponseResponse, QuestionResponse, ResponseRequest } from '@/Services/api/models/surveyModels';
import { ParticipantFormValues } from '../signInParticipant';
import { getValue } from '@testing-library/user-event/dist/utils';


export function AnswerQuestionsInAutoAttendant() {
    const location = useLocation();
    const { token } = useToken();
    const { eventID, participantID } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventInfos, setEventInfos] = useState<EventResponse>();
    const [backgroundImage, setBackgroundImage] = useState('');
    const [participantInfo, setParticipantInfo] = useState<GetParticipantResponse>()
    const [questions, setQuestions] = useState<QuestionResponse[]>()
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [insertedParticipant, setInsertedParticipant] = useState<GetParticipantResponse>();
    const [answerOptions, setAnswerOptions] = useState<DefaultResponseResponse[]>()
    const [errorOnPrint, setErrorOnPrint] = useState(false);
    const { language } = useParams()
    const [english, setEnglish] = useState<boolean>(false)
    const [modalMessage, setModalMessage] = useState<string | null>()
    const navigate = useAutoAttendantNavigate(location.state?.backgroundImage ?? backgroundImage);


    useIdleTimer({
        timeout: 40000,
        onIdle: () => {
            if (!errorOnPrint) navigate('/autoattendant/');
        },
    });


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues
    } = useForm();

    const getEventInfo = async () => {
        try {
            setIsLoading(true);
            const event = await ApiService.getEventById(parseInt(eventID));
            setEventInfos(event);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadEventImage = async () => {
        try {
            setIsLoading(true);
            const backgroundImage = await ApiService.getBackgroundImage(parseInt(eventID));
            setBackgroundImage(backgroundImage);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getParticipantInfo = async () => {
        const data = await ApiService.getParticipantByID(Number(participantID))
        setParticipantInfo(data)
    }

    const handleQuestionsByEvent = async () => {
        const response = await ApiService.getQuestionsByEventId(Number(eventID))
        setQuestions(response)
    }    


    const handleGetDefaultAnswers = async () => {
        const localAnswers = [];
        setIsLoading(true);
    
        try {
            const answerPromises = questions?.map(async (question) => {
                try {
                    const answers = await ApiService.getAllDefaultResponsesByQuestionId(Number(question.id));
                    localAnswers.push(...answers);
                } catch (error) {
                    toast.error(error.message);
                }
            });
    
            await Promise.all(answerPromises);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
            setAnswerOptions(localAnswers);
        }
    };

    const handleSendAnswers = async () => {
        setIsLoading(true)
        try {
            let answers = []
            const data = getValues()
            if (data) {
                Object.keys(data)?.map((questionId) => {
                    const newAnswer:ResponseRequest = {
                        questionId: Number(questionId),
                        id: 0,
                        description: data?.[questionId],
                        participantId: Number(participantID)
                    }
                    answers.push(newAnswer)
                })
            }
            const request = await ApiService.bulkInsertResponse(answers)
            if(eventInfos.labelConfiguration !== 'No label') { await handlePrintTicket(eventInfos, participantInfo) };
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getEventInfo();
        loadEventImage();
        getParticipantInfo()
        handleQuestionsByEvent()
    }, []);

    useEffect(() => {
        if(questions) {
            handleGetDefaultAnswers()
        }
    },[questions])
    
    return (
        <GambetaContainer backgroundUrl={location?.state?.backgroundImage ?? backgroundImage}>
            <SignInParticipantContainer
                backgroundUrl={location?.state?.backgroundImage ?? backgroundImage}
            >
                <BackArrow />
                <ModalLoading isActive={isLoading} />
                <ConfirmModal
                    show={showConfirmModal}
                    english={english}
                    onClose={() => {
                        navigate(`/autoattendant/checkin/${english? 'en' : 'pt'}`);
                        toast.success(english? 'Registration successful!' : 'Cadastrado com sucesso! realize seu checkIn');
                    }}
                    onConfirm={async () => {
                        try {
                            setIsLoading(true);
                            if (!eventInfos || !insertedParticipant) return;
                            await handlePrintTicket(eventInfos, insertedParticipant);
                            setIsLoading(false);

                            toast.success(english? 'Credential printed successfully' : 'Credencial impressa com sucesso');
                            navigate(`/autoattendant/${english? 'en' : 'pt'}`);
                        } catch (e) {
                            toast.error(english? 'A problem occured' : 'Não foi possível imprimir a credencial');
                        }
                    }}
                />
                <Form>
                    {questions?.map((question) =>
                        <Form.Group as={Row} className='mb-3'>
                                <Form.Label column sm={2}>
                                    *
                                    {question?.description}
                                </Form.Label>
                                <Col sm={10}>
                                    {question?.multiplesChoices?
                                        <Form.Select aria-label='Default select example' {...register(question.id?.toString())}>
                                            <option value={''}> -- Selecione -- </option>
                                            {answerOptions?.filter((answer) => Number(answer.questionId) === Number(question.id))?.map((answer) =>
                                                <option value={answer?.description}>{answer?.description}</option>
                                            )}
                                        </Form.Select>
                                    :
                                        <Form.Control type='text' {...register(question.id?.toString())}/>
                                    }
                                </Col>
                        </Form.Group>
                    )}
                    
                    <SubmitButton>
                        <TButton type='button' width={'500px'} onClick={handleSendAnswers}>
                            {english? 'Submit' : 'Enviar'}
                        </TButton>
                    </SubmitButton>
                </Form>
            </SignInParticipantContainer>
        </GambetaContainer>
    );
}
