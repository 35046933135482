import styled from 'styled-components';

interface ModalContainerProps {
    width?: string;
    height?: string;
}

export const ModalBlock = styled.div`
    align-items: center;
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding: 0.4rem;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    opacity: 1;
    z-index: 400;
`;

export const ModalOverlay = styled.a`
    background: rgba(1, 1, 1, 0.1);
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.4;
`;

export const ModalContainer = styled.div<ModalContainerProps>`
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    min-height: ${({ height }) => (height ? height : '75vh')};
    max-width: 100%;
    padding: 0 0.8rem;
    width: ${({ width }) => (width ? width : '100%')};
    animation: slide-down 0.2s ease 1;
    z-index: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

export const ModalBody = styled.div`
    overflow: hidden;
    padding: 30px 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
`;
