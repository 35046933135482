import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface ConfirmModalProps {
    show: boolean;
    english?: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void> | void;
}

export function ConfirmModal(props: ConfirmModalProps) {
    return (
        <>
            <Modal show={props.show} onHide={props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.english? 'Check-in' : 'CheckIn Participante'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.english? 'Print credential?' : 'Imprimir Credencial?'}</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={props.onClose}>
                        {props.english? 'No' : 'Não'}
                    </Button>
                    <Button variant='primary' onClick={props.onConfirm}>
                        {props.english? 'Yes' : 'Sim'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
