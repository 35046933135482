import styled from 'styled-components';

interface ButtonProps {
    width?: string;
    height?: string;
}

export const ButtonComponent = styled.button<ButtonProps>`
    border: none;
    padding: 8px 12px;

    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.steelblue};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.sm};

    width: ${(props) => props.width};
    height: ${(props) => props.height};
    cursor: pointer;
`;
