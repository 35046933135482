import styled from 'styled-components';

export const InputComponentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 10px;
    padding: 6px 12px;

    height: 34px;
    width: 100%;

    border: 1px solid #e5e6e7;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};

    transition: border-color 0.25s ease-in-out 0s, box-shadow 0.25s ease-in-out 0s;

    :focus-within {
        border: 1px solid ${({ theme }) => theme.colors.steelblue};
    }
`;

export const StyledInput = styled.input`
    background-color: transparent;
    outline: none;
    border: none;

    width: 100%;
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize.sm};
`;
