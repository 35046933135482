import styled from 'styled-components';

export const BackArrowContainer = styled.div`
    background: rgb(0 0 0 / 50%);
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;

    position: fixed;
    top: 0;
    left: 0;

    padding: 10px;
    margin: 50px;
`;
