import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import styled from 'styled-components';

export const BoxesContainer = styled.div`
    gap: 50px;
    text-align: left;
`;

export const Box = styled(WhiteBackground)`
    align-items: flex-start;
    text-align: left;

    padding: 20px;
    gap: 10px;
`;

export const Title = styled.h4`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: bold;
    margin: 0;
`;

export const Step = styled.p`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize.base};
`;
