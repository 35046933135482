'use client'
import React, { useEffect, useRef, useState } from 'react'
import { CameraOptions, useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import Webcam from 'react-webcam';

interface Props {
    onDetection: (img: any) => void;
}

export default function FaceDetectionComponent(props:Props) {
    const [feedback, setFeedback] = useState<string>('Por favor, mantenha seu rosto dentro da área')
    const [faceDetected, setFaceDetected] = useState<boolean>(false)
    const faceReconRef = useRef(null)

    const { webcamRef, boundingBox, isLoading, detected, facesDetected } = useFaceDetection({
        faceDetectionOptions: {
          model: 'short',
        
        },
        faceDetection: new FaceDetection.FaceDetection({
          locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
        }),
        camera: ({ mediaSrc, onFrame }: CameraOptions) => 
          new Camera(mediaSrc, {
            onFrame,
            width:300,
            height:500,
          }),
        
    });

    useEffect(() => {
        if(detected && facesDetected === 1) {
            setFeedback('Rosto detectado, por favor fique nesta posição')
            setTimeout(() => setFaceDetected(true), 1500)
        }
        
    },[detected])

    useEffect(() => {
        if(faceDetected && detected && facesDetected === 1) {
            const img = (webcamRef as any).current.getScreenshot();
            props.onDetection(img);
            
        }
    },[faceDetected])

    useEffect(() => {
        if(facesDetected > 1) {
            setFeedback('Por favor, certifique-se de que nenhuma outra pessoa está aparecendo na foto')
        } else {
            setFeedback('Por favor, mantenha seu rosto dentro da área')
        }
    },[facesDetected])


    {/*useEffect(() => {
        // Function to load the external script dynamically
        const loadScript = (src: string, callback: ((this: GlobalEventHandlers, ev: Event) => any) | null) => {
          const script = document.createElement("script");
          script.src = src;
          script.defer = true;
          script.onload = callback;
          document.body.appendChild(script);
        };
    
        // Callback after script is loaded to initialize FaceRecon
        const initializeFaceRecon = () => {
          const CID = (window as any).CID;
          if (CID && CID.FaceRecon) {
            const faceRecon = new CID.FaceRecon({
              onDetection: (img: any) => props.onDetection(img),
              element: faceReconRef.current,
              tolerance: 90,
              automaticDetection: true,
              timeToWait: null,
              playSize: 1,
              
            });
            faceRecon.run();
          }
        };
    
        // Load the external script and initialize after it has loaded
        loadScript("https://dev-front.apifacialid.com.br/facerecon/facerecon.js?v=1.1.1", initializeFaceRecon);
    
        // Cleanup the script when the component is unmounted
        return () => {
          const script = document.querySelector(`script[src="https://dev-front.apifacialid.com.br/facerecon/facerecon.js?v=1.1.1"]`);
          if (script) {
            document.body.removeChild(script);
          }
        };
    }, []);*/}

  return (
    <div style={{'position': 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <div style={{width: 300, height: 500, position: 'relative'}}>
            <Webcam
                ref={webcamRef}
                forceScreenshotSourceSize
                style={{
                    height:500,
                    width:300,
                    zIndex: 0
                }}
                mirrored={true}
            />
            <div style={{ 
                position: 'absolute', 
                width: 200, 
                height: 300, 
                top: 100, 
                left: 50, 
                zIndex: 2, 
                borderRadius: '50%', 
                border: detected ? '5px solid green' : '5px dashed red', 
                boxSizing: 'border-box'
                }} />
            </div>
        <h3 style={{color: '#FFF', fontSize: 'large'}}>{feedback}</h3>
        {/*<div id="elementoOndeFaceReconSeraInstanciado" ref={faceReconRef} />*/}
    </div>
  )
}
