import React from 'react';
import { Spinner, Bounce } from 'react-activity';
import 'react-activity/dist/Spinner.css';
import { useTheme } from 'styled-components';
import { ModalBlock, ModalBody, ModalContainer, ModalOverlay } from './styled';

interface ModalLoadingProps {
    isActive: boolean;
}

export default function ModalLoading({ isActive }: ModalLoadingProps) {
    const theme = useTheme();

    return (
        <>
            {isActive && (
                <ModalBlock>
                    <ModalContainer width={'20rem'} height={'20rem'}>
                        <ModalBody>
                            <Spinner color={theme.colors.steelblue} size={30} />
                        </ModalBody>
                    </ModalContainer>
                </ModalBlock>
            )}
        </>
    );
}
