import styled from "styled-components";

export const WhiteBoxContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    min-height: 80vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    padding: 20px;
    margin: 20px;

    table {
        min-height: 150px;
    }

    .header {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 41px;
            height: 22px;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }
    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
        min-height: 70vh;
    }

    th {
        font-weight: 700;
    }
    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    tr {
        height: 2vh;
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};
        }
    }
`;

export const CheckInContainer = styled.div`
    width: 30vw;
`

export const Title = styled.p`
    font-weight: bold;
`

export const Row = styled.div`

`

export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
`