import { Background } from '@/Components/Background'
import { Header } from '@/Components/Header'
import ModalLoading from '@/Components/ModalLoading'
import React, { useEffect, useRef, useState } from 'react'
import { CheckInContainer, DataContainer, Row, Title, WhiteBoxContainer } from './styled'
import { Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import ApiService from '@/Services/api/ApiService'
import { Attraction, AttractionParticipantCheckIn } from '@/Services/api/models/attractionsModels'
import { toast } from 'react-toastify'
import { GetParticipantResponse } from '@/Services/api/models/participantsModel'
import { TButton } from '@/Components/TButton'
import { CheckInParticipantRequest } from '@/Services/api/models/ControleDeAcessoModels'
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService'

export default function RoomCheckIn() {
  const input = useRef(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [participants, setParticipants] = useState<GetParticipantResponse[]>()
  const [idOrDoc, setIdOrDoc] = useState<string>()
  const { eventID, roomID } = useParams()


  const handleGetParticipants = async () => {
    setIsLoading(true)
    try {
      const data = await ApiService.getAllParticipantsByEvent(Number(eventID))
      setParticipants(data)
    }
    catch (e: any) {
      toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
    }
    finally{
        setIsLoading(false)
    }
  }

  const findParticipant = () => {
    const participantFound = participants?.find(participant => participant.id === Number(idOrDoc))?.id
    if(participantFound) {
      handleCheckIn(participantFound)
    }
    else {
      const participantFoundByDoc = participants?.find(participant => participant.documentNumber === idOrDoc)?.id
      if (participantFoundByDoc) {
        handleCheckIn(participantFoundByDoc)
      }
      else {
        toast.error('Participante não encontrado')
      }
    }

  }
  
  const handleCheckIn = async (participantId: number) => {
    setIsLoading(true)
    try {
      const checkInRequest:CheckInParticipantRequest = {
        rentpass_participant: participantId,
        room: Number(roomID),
        timestamp: new Date()
      }
      const response = await ControleDeAcessoService.checkInParticipant(checkInRequest)
      toast.success('Check-in realizado com sucesso')
    }
    catch (e: any) {
      toast.error(e?.response?.data?.participant_room?  `Participante inscrito na sala: ${e?.response?.data?.participant_room}` : 'Erro desconhecido')
    }
    finally{
        setIsLoading(false)
        if(input) {
          input?.current?.focus()
        }
    }

  }

  useEffect(() => {
    handleGetParticipants()
  },[])

  return (
    <Background>
      <ModalLoading isActive={isLoading} />
      <Header pageTitle='Controle de Acesso - Check-in' />
      <WhiteBoxContainer>
        <CheckInContainer>
          <Form.Control
            type='text'
            autoFocus
            placeholder='ID ou Documento do Participante'
            className='addField'
            onChange={(e) => setIdOrDoc(e.target.value)}
            ref={input}
          />
        </CheckInContainer>
        <DataContainer>
          <TButton onClick={findParticipant}>
            Check-in
          </TButton>
        </DataContainer>
      </WhiteBoxContainer>
    </Background>
  )
}
