import { SubmitHandler, useForm } from 'react-hook-form';
import { AiFillLock, AiOutlineMail } from 'react-icons/ai';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import * as yup from 'yup';

import { TextInput } from '@/Components/TInput';
import { TButton } from '@/Components/TButton';
import {
    LoginBackground,
    SignInContainer,
    SignInForm,
    SignInSubTitle,
    SignInTitle,
    WarnText,
} from './styled';
import { SignInResponse } from '@/Services/api/models/signInModel';
import ModalLoading from '@/Components/ModalLoading';
import ApiService from '@/Services/api/ApiService';
import { toast } from 'react-toastify';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { useToken } from '@/Components/UseToken';
import { useNavigate } from 'react-router-dom';

interface SignInFormValues {
    email: string;
    senha: string;
}

export function SignIn() {
    const [apiRequestError, setApiRequestError] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const { setToken } = useToken();

    const schema = yup
        .object({
            email: yup.string().required('O campo Email é obrigatório'),
            senha: yup.string().required('O campo Senha é obrigatório'),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignInFormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmitForm: SubmitHandler<SignInFormValues> = async (data) => {
        try {
            setIsLoading(true);
            const response = await ApiService.autenticarUsuarioAsync({
                email: data.email,
                password: data.senha,
            });
            ApiService.setTokenUsuarioAutenticado(response.token);
            const userInfo = await ApiService.getUserByID(response.user.id);
            setToken(response, userInfo);
            navigate('/');
        } catch (err: any) {
            if (err.response) {
                setApiRequestError('Login inválido');
            } else {
                setApiRequestError('Falha no Login');
                toast.error(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <LoginBackground>
            <ModalLoading isActive={isLoading} />
            <SignInContainer>
                <SignInTitle> Login - Rentpass </SignInTitle>
                <SignInSubTitle> Entre com seus dados </SignInSubTitle>
                <SignInForm onSubmit={handleSubmit(onSubmitForm)}>
                    <TextInput.Root>
                        <TextInput.Icon>
                            {' '}
                            <AiOutlineMail size={20} color={'black'} />{' '}
                        </TextInput.Icon>
                        <TextInput.Input
                            {...register('email', { required: true })}
                            placeholder='E-mail'
                        />
                    </TextInput.Root>
                    {errors.email?.message && <WarnText> {errors.email.message} </WarnText>}
                    <TextInput.Root>
                        <TextInput.Icon>
                            {' '}
                            <AiFillLock size={20} color={'black'} />{' '}
                        </TextInput.Icon>
                        <TextInput.Input
                            {...register('senha', { required: true })}
                            placeholder='Senha'
                            type='password'
                        />
                    </TextInput.Root>
                    {errors.senha?.message && <WarnText> {errors.senha.message} </WarnText>}
                    {apiRequestError ? <WarnText> {apiRequestError} </WarnText> : <> </>}
                    <TButton type='submit'> Entrar </TButton>
                </SignInForm>
            </SignInContainer>
        </LoginBackground>
    );
}
