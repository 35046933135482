import { ReactNode, ButtonHTMLAttributes } from 'react';

import { ButtonComponent } from './styled';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
}

export function FormButton({ children, ...props }: ButtonProps) {
    return <ButtonComponent {...props}>{children}</ButtonComponent>;
}
