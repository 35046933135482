import axios, { AxiosError, AxiosResponse } from 'axios';
import { Link, LinkRequest, RecipientRequest, RegistrationTokenRequest, RsvpEventRequest, RsvpEventResponse, SymplaIntegrationRequest } from '../api/models/rsvpModels';

//https://rsvp-api.nextlevelcode.com.br/

let configuration = {
    baseURL: 'https://rsvp-api.nextlevelcode.com.br/'
};

export default class RsvpService {
    private static apiServiceAxios = axios.create(configuration);

    static loginData: { token?: string } = {
        token: undefined,
    };

    static setTokenUsuarioAutenticado(token: string) {
        this.apiServiceAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        this.loginData.token = token;
    }

    private static enviarRequestAsync = async <T>(
        verb: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        path: string,
        data: any = undefined,
        auth?: any,
    ): Promise<AxiosResponse<T, any>> => {

        try {
            switch (verb) {
                case 'GET':
                    return this.apiServiceAxios.get<T>(path, {
                        params: data,
                    });
                case 'PUT':
                case 'PATCH':
                case 'POST':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        url: path,
                        auth: auth,
                    });
                case 'DELETE':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        url: path,
                    });

                default:
                    throw new Error('O verbo usado na requisição não é suportado pelo sistema.');
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response) {
                    // O servidor respondeu com um status code diferente de 200
                    if (error.response.data.erros.length > 0) {
                        throw new Error(error.response.data.erros[0].mensagem);
                    }
                    throw new Error('Ocorreu um erro ao processar sua solicitação.');
                } else if (error.request) {
                    // A requisição foi feita, porém não foi recebida nenhuma resposta do servidor
                    throw new Error('Não foi possível se comunicar com o servidor.');
                } else {
                    // Um erro ocorreu antes do envio da requisição
                    throw error;
                }
            } else {
                throw error;
            }
        }
    };

    public static async getPaymentLinkByParticipantId(participantId: number) {
        const response = await this.enviarRequestAsync<{'url': string, 'order_id': number}>(
            'GET',
            `/get-payment-link-by-participant-id/${participantId}/`
        )
        return response.data;
    }

    public static async sendConfirmationEmail(participantId: number) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/send-participant-email/${participantId}/`,
            {
                "token": token?.token,
                "user_id": token?.user?.id
            }
        )
        return response.data;
    }

    public static async checkPaymentStatus(orderId: number) {
        const response = await this.enviarRequestAsync<{status: string}>(
            'GET',
            `/get-payment-status-by-order/${orderId}/`
        )
        return response.data;
    }

    public static async getLinksByRentpassEvent(eventId: number) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        const response = await this.enviarRequestAsync<Link[]>(
            'GET',
            `/get-links-by-rentpass-event/`,
            {
                "event": eventId,
                "token": token?.token,
                "id": token?.user?.id
            }
        )
        return response.data;
    }

    public static async getEventByRentpassId(eventId: number) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        const response = await this.enviarRequestAsync<RsvpEventResponse>(
            'GET',
            `/get-event-by-rentpass-id/${eventId}/`,
            {
                "token": token?.token,
                "id": token?.user?.id
            }
        )
        return response.data;
    }

    public static async createEvent(data: RsvpEventRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await this.enviarRequestAsync<RsvpEventResponse>(
            data?.update? 'PATCH' : 'POST',
            `/set-event/`,
            data
        )
        return response.data;
    }

    public static async createLink(data: LinkRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        if (data.id) {
            const response = await axios.patchForm(
                configuration.baseURL + '/set-link/',
                data
            )
            return response.data;
        }
        else {
            const response = await axios.postForm(
                configuration.baseURL + '/set-link/',
                data
            )
            return response.data;
        }
    }

    public static async createRecipient(data: RecipientRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/create-recipient/',
            data
        )
        return response.data;
    }

    public static async enableSymplaIntegration(data: SymplaIntegrationRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/enable-sympla-integration/',
            data
        )
        return response.data;
    }

    public static async disableSymplaIntegration(data: SymplaIntegrationRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/disable-sympla-integration/',
            data
        )
        return response.data;
    }

    public static async enableHotmartIntegration(data: SymplaIntegrationRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/enable-hotmart-integration/',
            data
        )
        return response.data;
    }

    public static async disableHotmartIntegration(data: SymplaIntegrationRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/disable-hotmart-integration/',
            data
        )
        return response.data;
    }

    public static async enableBlinketIntegration(data: SymplaIntegrationRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/enable-blinket-integration/',
            data
        )
        return response.data;
    }

    public static async disableBlinketIntegration(data: SymplaIntegrationRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.token = token?.token
        data.user_id = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/disable-blinket-integration/',
            data
        )
        return response.data;
    }

    public static async generateRegistrationToken(data: RegistrationTokenRequest) {
        const token = await JSON.parse(localStorage?.getItem('token'))
        data.auth_token = token?.token
        data.created_by = token?.user?.id
        const response = await axios.postForm(
            configuration.baseURL + '/generate-registration-token/',
            data
        )
        return response.data;
    }
}
