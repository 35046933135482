import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import { TButton } from '@/Components/TButton';
import ApiService from '@/Services/api/ApiService';
import { CreateUserRequest, IUserGroup, UsersResponse } from '@/Services/api/models/usersModel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Col, Form, ListGroup, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashedHR, EventAddForm, Link, SubmitButton, WarnText } from './styled';

import Select from 'react-select';
import * as yup from 'yup';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ModalLoading from '@/Components/ModalLoading';

import { toast } from 'react-toastify';
import { EventResponse } from '@/Services/api/models/eventModel';
import { formatDateToString } from '@/util/FormatDate';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import RsvpService from '@/Services/rsvp/RsvpService';
import { RegistrationTokenRequest } from '@/Services/api/models/rsvpModels';
import { useToken } from '@/Components/UseToken';

interface UserFormValues {
    events: { label: string; value: number }[];
    userGroup: {
        label: string;
        value: keyof typeof IUserGroup;
    };
    terms: string;
}

export function InviteUser() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isRecipient, setIsRecipient] = useState<boolean>(false)
    const [link, setLink] = useState<string>()

    const { token } = useToken();

    const schema = yup
        .object({
            events: yup.array().of(yup.object().shape({
                label: yup.string().required('É necessário escolher um evento'),
                value: yup.number().required('É necessário escolher um evento'),
            })).min(1, 'É necessário escolher um evento').required('É necessário escolher um evento'),
            userGroup: yup.object().shape({
                label: yup.string().required('status is required (from label)'),
                value: yup.mixed<IUserGroup>().required('É necessário escolher um grupo'),
            })
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues,
        watch,
    } = useForm<UserFormValues>({
        resolver: yupResolver(schema),
    });

    const [userToEdit, setUserToEdit] = useState<UsersResponse>();
    const [availableEvents, setAvailableEvents] = useState<EventResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [canViculateToEvent, setCanVinculateToEvent] = useState<boolean>(false);

    const handleGetUserByID = async (id: number) => {
        try {
            setIsLoading(true);
            const response = await ApiService.getUserByID(id);
            setUserToEdit(response);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetEvents = async () => {
        try {
            setIsLoading(true);
            const response = await ApiService.getEventosBySpecification(
                location?.state?.event? {id: location?.state?.event} : {active: true}
            );
            setAvailableEvents(response);
        } catch (e) {
            toast.error('Houve um erro ao trazer os eventos disponíveis');
        } finally {
            setIsLoading(false);
        }
    };

    console.log(errors.events)

    useEffect(() => {
        handleGetEvents();
    }, []);

    useEffect(() => {
        if (getValues('userGroup.value') === 'ADMIN') {
            setCanVinculateToEvent(false);
        } else {
            setCanVinculateToEvent(true);
        }
    }, [watch('userGroup')]);

    useEffect(() => {
        if (location?.state?.userId) {
            handleGetUserByID(+location.state.userId);
        }
    }, [location.state]);

    const handleFullFillEvent = async (
        events: number[],
    ) => {
        try {
            setIsLoading(true);
            const eventsInfo = await Promise.all(events.map(async (event) => {
                const response =  await ApiService.getEventById(event)
                return {label: response.description, value: response.id}
            }))
            setValue('events',eventsInfo)
        } catch (e) {
            toast.error('Erro ao carregar evento vinculado ao usuário');
        } finally {
            setIsLoading(false);
        }
    };


    const handleSubmitForm: SubmitHandler<UserFormValues> = async (data) => {
        try {
            setIsLoading(true);
            const request:RegistrationTokenRequest = {
                account_type: data?.userGroup?.value,
                rentpass_event: data?.events?.[0]?.value,
                terms: data?.terms ?? null,
                created_by: 0,
                auth_token: ''
            }
            const response = await RsvpService.generateRegistrationToken(request)
            const linkString = `https://accounts.rentpass.com.br?token=${response?.token}`
            toast.success('Link copiado!')
            navigator.clipboard.writeText(linkString)
            setLink(linkString)
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEventSelect = (selectedOption: { label: string; value: number } | null) => {
        const currentEvents = getValues('events') || [];
        if (selectedOption && currentEvents.filter(evento => evento.value === selectedOption.value).length === 0) {
            currentEvents.push(selectedOption);
        }
        else {
            currentEvents.splice(currentEvents.indexOf(selectedOption),1)
        }
        setValue('events', currentEvents);
    };



    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Edição de Usuário' />
            <WhiteBackground>
                <EventAddForm>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Termos
                            </Form.Label>
                            <Col sm={10}>
                                <textarea className='form-control textarea' {...register('terms')} />
                                {errors.terms?.message && (
                                    <WarnText> {errors.terms.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Grupos
                            </Form.Label>
                            <Col sm={10}>
                                <Controller
                                    name={'userGroup'}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            className='groupSelect'
                                            {...field}
                                            options={(
                                                Object.keys(
                                                    IUserGroup,
                                                ) as (keyof typeof IUserGroup)[]
                                            ).filter((group) => group !== "ADMIN" && group !== "PARTNER_ADMIN" && group !== 'SELF_SERVICE').map((groupMapped) => {
                                                return {
                                                    label: IUserGroup[groupMapped].toLowerCase(),
                                                    value: groupMapped,
                                                };
                                            })}
                                            styles={{
                                                placeholder: (styles) => {
                                                    return {
                                                        ...styles,
                                                    };
                                                },
                                                control: (styles) => {
                                                    return {
                                                        ...styles,
                                                        ':hover': {},
                                                        ':focus-within': {
                                                            border: '1px solid black',
                                                        },
                                                    };
                                                },
                                                container: (styles) => {
                                                    return {
                                                        ...styles,
                                                        textTransform: 'capitalize',
                                                    };
                                                },
                                            }}
                                        />
                                    )}
                                />
                                {errors.userGroup?.value && (
                                    <WarnText>
                                        {' '}
                                        <> {errors.userGroup.value.message} </>{' '}
                                    </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Evento Vinculado
                            </Form.Label>
                            <Col sm={10}>
                                <Controller
                                    name={'events'}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            className='groupSelect'
                                            {...field}
                                            options={availableEvents.map((evento) => ({
                                                label: evento.description,
                                                value: evento.id
                                            })) as { label: string; value: number }[]}
                                            isDisabled={!canViculateToEvent}
                                            onChange={handleEventSelect}
                                            styles={{
                                                placeholder: (styles) => {
                                                    return {
                                                        ...styles,
                                                    };
                                                },
                                                control: (styles) => {
                                                    return {
                                                        ...styles,
                                                        ':hover': {},
                                                        ':focus-within': {
                                                            border: '1px solid black',
                                                        },
                                                    };
                                                },
                                                container: (styles) => {
                                                    return {
                                                        ...styles,
                                                        textTransform: 'capitalize',
                                                    };
                                                },
                                            }}
                                        />
                                    )}
                                />
                                {errors.events?.message && (
                                    <WarnText>
                                        {' '}
                                        <> {errors.events?.message} </>{' '}
                                    </WarnText>
                                )}
                            </Col>
                            <ListGroup>
                                {watch('events',[])?.map(evento => 
                                    <ListGroup.Item onClick={() => handleEventSelect(evento)}>{evento.label}</ListGroup.Item>
                                )}
                            </ListGroup>
                        </Form.Group>
                        <DashedHR />
                        {watch('userGroup.value') === 'CLIENT' && <>
                            <Form.Group as={Row} className='mb-3'>
                                <Col sm={{ span: 10, offset: 2 }}>
                                    <Form.Check
                                        onChange={(v) => setIsRecipient(v?.target?.checked)}
                                        type={'checkbox'}
                                        id={`Inativo-${'checkbox'}`}
                                        label={`Cadastrar como recebedor`}
                                    />
                                </Col>
                            </Form.Group>
                            <DashedHR />
                        </>}
                        {link?
                            <Link href={link}>{link}</Link>
                        :
                        <SubmitButton>
                            <TButton> Gerar </TButton>
                        </SubmitButton>
                        }
                    </Form>
                </EventAddForm>
            </WhiteBackground>
        </Background>
    );
}
