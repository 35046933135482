import { useTheme } from 'styled-components';
import {
    AlredyCheckedInContainer,
    ContentContainer,
    InputContainer,
    LabelContainer,
    ModalContainer,
    ModalFooterButton,
    ModalFooterContainer,
    Title,
} from './styled';
import TModal from '../TModal';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

interface Props {
    hideModal: () => void;
    onButtonClick: () => void;
    isActive: boolean;
    participant?: GetParticipantResponse;
}

export default function ModalConfirmIdentity({
    hideModal,
    onButtonClick,
    isActive,
    participant,
}: Props) {
    const theme = useTheme();

    return (
        <TModal
            active={isActive}
            width='30%'
            height='30vh'
            hideModal={() => undefined}
            footer={
                <>
                    {!participant?.checkinDate ? (
                        <ModalFooterContainer>
                            <ModalFooterButton onClick={hideModal} backgroundColor='red'>
                                <ThumbDownIcon />
                            </ModalFooterButton>
                            <ModalFooterButton onClick={onButtonClick} backgroundColor='green'>
                                <ThumbUpIcon />
                            </ModalFooterButton>
                        </ModalFooterContainer>
                    ) : (
                        <AlredyCheckedInContainer>
                            <ModalFooterButton
                                onClick={hideModal}
                                backgroundColor={theme.colors.steelblue}
                            >
                                {' '}
                                OK{' '}
                            </ModalFooterButton>
                        </AlredyCheckedInContainer>
                    )}
                </>
            }
        >
            <ModalContainer>
                <Title>
                    {' '}
                    {!participant?.checkinDate
                        ? 'Confirmar CheckIn?'
                        : 'Você já realizou o seu CheckIn'}{' '}
                </Title>
                <ContentContainer>
                    <LabelContainer>
                        <label> Nome: </label>
                        <label> Documento: </label>
                        <label></label>
                    </LabelContainer>
                    <InputContainer>
                        <div> {`${participant?.name} ${participant?.lastName}`} </div>
                        <div> {participant?.documentNumber} </div>
                    </InputContainer>
                </ContentContainer>
            </ModalContainer>
        </TModal>
    );
}
