import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import React, { useEffect, useState } from 'react'
import { Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { ItemRow, ModalContainer, RedText, StyledForm, StyledRow, SubmitButton, Text, Title } from './styled';
import { TButton } from '../TButton';
import { useForm } from 'react-hook-form';
import { WarnText } from '@/Pages/Events/Adicionar/styled';
import { QuestionRequest } from '@/Services/api/models/surveyModels';
import ApiService from '@/Services/api/ApiService';

interface FormValues {
    answers: string[];
}

interface Props {
    show: boolean;
    question: QuestionRequest;
    saveCallback: (data: string[]) => void;
}

export default function QuestionOptionsModal(props:Props) {
    const [answerDescription, setAnswerDescription] = useState<string>()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
        getValues,
    } = useForm<FormValues>()

    const handleSaveButtonClick = async (data: FormValues) => {
        props.saveCallback(data.answers)
    }

    const handleAddAnswer = async () => {
        let answers = watch('answers')
        if(answers) {
            answers.push(answerDescription)
        }
        else {
            answers = [answerDescription]
        }
        setValue('answers',answers)
    }

    const handleRemoveAnswer = (answer: string) => {
        const currentAnswers = getValues('answers')
        const newAnswers = currentAnswers?.filter(actualAnswer => actualAnswer !== answer)
        setValue('answers',newAnswers)
    }

  return (
    <Modal show={props.show}>
        <ModalContainer>
            <StyledForm>
                <Form onSubmit={handleSubmit(handleSaveButtonClick)}>
                    <Title>Opções de Resposta</Title>
                    <StyledRow>
                        <Form.Control onChange={(e) => setAnswerDescription(e.target.value)}/>
                            {errors.answers?.message && (
                                <WarnText> {errors.answers.message} </WarnText>
                            )}
                            <TButton type='button' onClick={handleAddAnswer}>Adicionar</TButton>
                    </StyledRow>
                    <ListGroup>
                        {watch('answers',[])?.map(answer => 
                            <ListGroup.Item>
                                <ItemRow>
                                    {answer}
                                    <RedText onClick={() => handleRemoveAnswer(answer)}>Remover</RedText>
                                </ItemRow>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                    <SubmitButton>
                        <TButton type='submit'> Salvar </TButton>
                    </SubmitButton>
                </Form>
            </StyledForm>
        </ModalContainer>
    </Modal>
  )
}