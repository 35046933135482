import { ReactNode, ButtonHTMLAttributes } from 'react';

import { ButtonComponent } from './styled';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    width?: string;
    height?: string;
}

export function TButton({ children, ...props }: ButtonProps) {
    return <ButtonComponent {...props}>{children}</ButtonComponent>;
}
