import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Container } from './styled';

export default function index() {
  return (
    <Container>
      <CloseIcon />
    </Container>
  )
}
