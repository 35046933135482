export enum IParticipantSearchBy {
    'NAME' = 'Nome',
    'EMAIL' = 'Email',
    'DOCUMENT_NUMBER' = 'Nº DE DOCUMENTO',
    'NAME_AND_DOCUMENT_NUMBER' = 'NOME / Nº DE DOCUMENTO',
    'PHONE_NUMBER' = 'Telefone',
}

export enum IEnglishParticipantSearchBy {
    'NAME' = 'Name',
    'EMAIL' = 'E-mail',
    'DOCUMENT_NUMBER' = 'Document Number',
    'NAME_AND_DOCUMENT_NUMBER' = 'Name or Document Number',
    'PHONE_NUMBER' = 'Phone',
}


export interface EventResponse {
    id: number;
    description: string;
    details: string;
    initialDate: string;
    finalDate: string;
    active: boolean;
    backgroundImage: string;
    place: string;
    allowDuplicateDocumentNumber: boolean;
    allowParticipantAutoRegistration: boolean;
    participantSearchBy: keyof typeof IParticipantSearchBy;
    serviceOrder: string;
    requiredFields: string;
    labelConfiguration: string;
    removeTitleLabel: boolean;
    labelResolution: string;
    checkinType: string;
    useVirtualKeyboard: boolean;
    kitQuantityLimit: number | null;
    printerModel: string;
    malexControl: boolean;
    showCheckinButton: boolean;
    attractionControl: boolean;
    labelPrintCount: number;
    surveyControl: boolean;
    additionalCategories: string;
    accessControl: boolean;
    blinketIntegration: boolean;
    symplaIntegration: boolean;
    hotmartIntegration: boolean;
    welcomeMessage: string;
    qrCodeUrl: string;
    buttonColor: string;
    facialRecognition: boolean;
    webhooks: boolean;
    totemRent: boolean;
}

export interface CreateEventRequest {
    id?: number;
    active: boolean;
    allowDuplicateDocumentNumber: boolean;
    allowParticipantAutoRegistration: boolean;
    description: string;
    details: string;
    finalDate: string;
    initialDate: string;
    place: string;
    backgroundImage?: string;
    participantSearchBy: keyof typeof IParticipantSearchBy;
    serviceOrder: string;
    requiredFields: string;
    removeTitleLabel: boolean;
    labelConfiguration?: string;
    labelResolution?: string;
    checkinType: string;
    useVirtualKeyboard: boolean;
    kitQuantityLimit?: number | null;
    printerModel?: string;
    malexControl: boolean;
    showCheckinButton: boolean;
    attractionControl: boolean;
    labelPrintCount: number;
    surveyControl: boolean;
    additionalCategories: string;
    accessControl: boolean;
    blinketIntegration: boolean;
    symplaIntegration: boolean;
    hotmartIntegration: boolean;
    welcomeMessage: string;
    qrCodeUrl: string;
    buttonColor: string;
    facialRecognition: boolean;
    webhooks: boolean;
    totemRent: boolean;
}

export interface FilterOptions {
    active?: boolean;
    description?: string;
    id?: number;
    serviceOrder?: string;
}

export interface IMetrics {
    participantsCheckIn: number;
    participantsToArrive: number;
    participantsFromAdmin: number;
    participantsFromExcel: number;
    participantsTotal: number;
    participantsByCategory: Object;
    kitQuantityLimit: number;
    participantsCekWithdraw: number;
    participantsCheckInByCategory: Object;
    participantsByTimeSpan: any;
    averageCheckInDuration: number;
    participantsByEventCategory: number;
    participantsCheckInByEventCategory: number;
}
