import { useTheme } from 'styled-components';
import {
    AlredyCheckedInContainer,
    ContentContainer,
    ImportExcel,
    InputContainer,
    LabelContainer,
    ModalContainer,
    ModalFooterButton,
    ModalFooterContainer,
    Title,
} from './styled';
import TModal from '../TModal';
import { FormButton } from '../FormButton';
import { useRef } from 'react';
import CloseButton from '../CloseButton';


interface Props {
    hideModal: () => void;
    onButtonClick: () => void;
    onInputClick: (file) => void;
    isActive: boolean;
}

export default function ModalImportParticipant({
    hideModal,
    onButtonClick,
    onInputClick,
    isActive,
}: Props) {
    const theme = useTheme();
    const fileInputRef = useRef(null);

    const handleOpenInput = () => {
        fileInputRef.current.click(); // Programmatically trigger file input click
    };

    return (
    <>
        <TModal
            active={isActive}
            width='30%'
            height='30vh'
            hideModal={() => undefined}
            footer={
                <>
                </>
            }
        >
            <ModalContainer>
                <a onClick={() => hideModal()}>
                    <CloseButton />
                </a>
                <Title>
                    Importar participantes via Excel                    
                </Title>
                <ContentContainer>
                    <LabelContainer>
                        <label> Baixar modelo de importação </label>
                    </LabelContainer>
                    <InputContainer>
                        <FormButton
                            onClick={() => {onButtonClick()}}
                        >
                            Baixar planilha
                        </FormButton>
                    </InputContainer>
                    <LabelContainer>
                        <label> Importar planilha Excel </label>
                    </LabelContainer>
                    <InputContainer>
                        <div> 
                            <ImportExcel>
                                <FormButton
                                    onClick={() => {handleOpenInput()}}
                                >
                                    Importar
                                </FormButton>
                                <input
                                    type='file'
                                    name='arquivo'
                                    id='arquivo'
                                    ref={fileInputRef}
                                    onChange={(e: any) => {
                                        onInputClick(e.target.files[0]);
                                    }}
                                    style={{
                                        position: 'absolute',
                                        width: '1px',
                                        height: '1px',
                                        padding: '0',
                                        margin: '-1px',
                                        overflow: 'hidden',
                                        clip: 'rect(0, 0, 0, 0)',
                                        border: '0',
                                    }}
                                />
                            </ImportExcel> 
                        </div>
                    </InputContainer>
                </ContentContainer>
            </ModalContainer>
        </TModal>
    </>
    );
}
