import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ModalContainer, Row, Text } from './styled';
import { TButton } from '../TButton';
import RsvpService from '@/Services/rsvp/RsvpService';
import { Send } from '@mui/icons-material';
import ApiService from '@/Services/api/ApiService';
import { toast } from 'react-toastify';
import ModalLoading from '../ModalLoading';

interface Props {
    show: boolean
    onButtonClick: () => void;
    participant: GetParticipantResponse;
}

export default function ModalParticipantInfo({
    show,
    onButtonClick,
    participant
}:Props) {
    const [additionalInfo, setAdditionalInfo] = useState<any[]>([])
    const [paymentLink, setPaymentLink] = useState<{url: string, order_id: number}>()
    const [isSending, setIsSending] = useState<boolean>(false)

    const getPaymentLink = async () => {
        if(participant && participant.linkOrigin) {
            const paymentLink = await RsvpService.getPaymentLinkByParticipantId(participant?.id)
            setPaymentLink(paymentLink)
        } else {
            setPaymentLink(undefined)
        }
    }

    const getAdditionalInfo = async () => {
        if(participant?.additionalInformation) {
            const data = participant?.additionalInformation?.replace('[','').replace(']','').split(',')
            const jsonData = data?.map((item) => JSON.parse(item)) ?? []
            setAdditionalInfo(jsonData)
        }
    }

    const sendConfirmationEmail = async () => {
        setIsSending(true)
        try {
            const response = await RsvpService.sendConfirmationEmail(participant?.id)
            toast.success('E-mail enviado!')
        } catch (error: any) {
            toast.error('Não foi possível enviar o e-mail')
        }
        setIsSending(false)
    }

    useEffect(() => {
        getAdditionalInfo()
        getPaymentLink()
    },[participant])

  return (
    <Modal show={show}>
        <ModalContainer>
            <Text>ID: {participant?.id}</Text>
            <Text>Nome: {participant?.name}</Text>
            <Text>Sobrenome: {participant?.lastName}</Text>
            <Text>Documento: {participant?.documentNumber}</Text>
            {participant?.state && <Text>Estado: {participant?.state}</Text>}
            {participant?.city && <Text>Cidade: {participant?.city}</Text>}
            {participant?.companyName && <Text>Empresa: {participant?.companyName}</Text>}
            {participant?.companyPosition && <Text>Cargo: {participant?.companyPosition}</Text>}
            {participant?.cellPhoneNumber && <Text>Telefone Celular: {participant?.cellPhoneNumber}</Text>}
            {participant?.commercialPhoneNumber && <Text>Telefone Celular: {participant?.commercialPhoneNumber}</Text>}
            {participant?.email && <Text>E-mail: {participant?.email}</Text>}
            {participant?.linkOrigin && <Text>Origem: {participant?.linkOrigin}</Text>}
            {participant?.additionalInformation && 
                additionalInfo?.map(key => 
                    <Text>{`${Object.keys(key)[0]}: ${Object.values(key)[0]}`}</Text>
                )
            }
            {participant?.linkOrigin && <Text>ID do pedido: {paymentLink?.order_id}</Text>}
            {!participant?.active && participant?.linkOrigin && <Text style={{whiteSpace:'normal', wordBreak: 'break-all'}}>Link de pagamento: {paymentLink?.url?.replaceAll(' ', '%20')}</Text>}
            {participant?.linkOrigin && 
                <Row onClick={sendConfirmationEmail}>
                    {isSending?
                        <ModalLoading isActive />
                    :
                        <>
                            <Text>Enviar e-mail de confirmação</Text>
                            <Send />
                        </>
                    }
                </Row>
            }
            <TButton onClick={onButtonClick}>
                Fechar
            </TButton>
        </ModalContainer>
    </Modal>
  )
}
