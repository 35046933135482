import styled from 'styled-components';
import { keyframes } from 'styled-components'
import { Title } from '../Homepage/styled';
import { Form } from 'react-bootstrap';
import { TButton } from '@/Components/TButton';
import { ArrowRightAlt } from '@mui/icons-material';

interface CheckInProps {
    backgroundUrl: string;
}

interface ButtonProps {
    backgroundColor: string;
}

export const CheckInContainer = styled.div<CheckInProps>`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: right;
    }

    .form-label {
        text-align: left;
        width: 100%;
    }

    .form-control {
        width: 500px;
        height: 60px;

        color: transparent;
        text-shadow: 0 0 0 ${({ theme }) => theme.colors.black};
        border-radius: 15px;
    }
`;

export const SearchByContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 10px;
    border-radius: 30px;

    background: rgb(0 0 0 / 50%);
    min-height: 25vh;
    width: 800px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;

export const CheckInForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const FormLabel = styled(Form.Label)`
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.fontSize['xl']};
`;

export const CheckoutButton = styled(TButton)<ButtonProps>`
    width: 300px;
    height: 85px;
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: ${(props) => props.backgroundColor}
`;

export const CheckInTitle = styled(Title)`
    margin-top: 0px;
    font-size: ${({ theme }) => theme.fontSize['3xl']};
`;

export const ErrorMessage = styled(Title)`
    margin-top: 0px;
    font-size: ${({ theme }) => theme.fontSize['2xl']};
`;

export const AppearAnimation = keyframes`
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
`

export const WelcomeMessage = styled(Title)`
    margin-top: 10px;
    font-size: ${({ theme }) => theme.fontSize['xl']};
    text-align: center;
    animation-name: ${AppearAnimation};
    animation-duration: 2s;
`;


export const KeyboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 800px;
    opacity: 70%;
`

export const bold = styled.div`
    font-weight: bold;
`

export const PaymentContainer = styled.div`
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    color: #fff;
`
export const QRCodeContainer = styled.div`
    border-radius: 30px;
    background-color: #FFF;
    display: flex;
    height: 250px;
    width: 250px;
    align-items: center;
    justify-content: center;
`

export const AnimationContainer = styled.div`
    display: flex;
    position: fixed;
    right: 50px;
    bottom: 50px;
    background-color: #0006;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    heigth: 550px;
    width: 300px;
    border-radius: 30px;
    align-items: center;
    jusify-content: center;
    flex-direction: column;
    padding: 2%;
`

export const Animation = styled.div`
`

export const PhoneImg = styled.img`
    width:  270px;
    height: 270px;
    bottom: 0px;
    left: 0px;
`
export const FeixeImg = styled.img`
    width: 150px;
    aspect-ratio: 9/16;
    position: absolute;
    bottom: 75px;
    left: 68px;
    z-index: 1;
    animation-name: ${AppearAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
`