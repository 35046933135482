import { useTheme } from 'styled-components';
import {
    AlredyCheckedInContainer,
    ContentContainer,
    ImportExcel,
    InputContainer,
    LabelContainer,
    ModalContainer,
    ModalFooterButton,
    ModalFooterContainer,
    QRCodeContainer,
    Title,
} from './styled';
import TModal from '../TModal';
import { FormButton } from '../FormButton';
import { useEffect, useRef, useState } from 'react';
import CloseButton from '../CloseButton';
import { Form } from 'react-bootstrap';
import { EventResponse } from '@/Services/api/models/eventModel';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { Group } from '@/Services/api/models/ControleDeAcessoModels';
import QRCode from 'react-qr-code';
import ApiService from '@/Services/api/ApiService';
import { handlePrintAppConfig } from '@/util/print';


interface Props {
    event: EventResponse
    hideModal: () => void;
    onButtonClick: () => void;
    onInputClick: (file) => void;
    isActive: boolean;
}

export default function ModalAppConfig({
    event,
    hideModal,
    onButtonClick,
    onInputClick,
    isActive,
}: Props) {
    const theme = useTheme();
    const [module, setModule] = useState<string>()
    const [modules, setModules] = useState<string[]>([])
    const [options, setOptions] = useState<any>()
    const [groups, setGroups] = useState<Group[]>()
    const [room, setRoom] = useState<number>()
    const [qrCodeData, setQRCodeData] = useState<{auth: string, module?: string, moduleParams?: {room?: number, event: number, attraction?: number, name: string}, event?: string, printerIp?: string, serverUrl?:string}>()
    const [printerIp, setPrinterIp] = useState<string>()
    const [apiAddress, setApiAddress] = useState<string>()

    const getRooms = async () => {
        const response = await ControleDeAcessoService.getRoomsByEvent(event?.id)
        const groups = await ControleDeAcessoService.getGroupsByEvent(event?.id)
        setGroups(groups)
        setOptions(response)
        
    }

    const getAttractions = async () => {
        const response = await ApiService.attractionFindByEvent(event?.id)
        setOptions(response)
    }

    const handlePrintConfig = async () => {
        const data = JSON.stringify(qrCodeData)
        const response = handlePrintAppConfig(
            event,
            module === 'CEK'? module : module === 'Capture Control'? `${module} - ${options.find(opt => opt.id === room)?.name}` : `${module} - ${options.find(opt => opt.id === room)?.room_name}`,
            data
        )
    }

    useEffect(() => {
        if(event) {
            let m = ['Autoatendimento']
            if (event.accessControl) {
                m.push('Controle de Acesso')
            }
            if (event.attractionControl) {
                m.push('Capture Control')
            }
            if (event.kitQuantityLimit > 0) {
                m.push('CEK')
            }
            setModules(m)
        }
    },[event])

    useEffect(() => {
        if(module === 'Controle de Acesso') {
            getRooms()
        }
        if(module === 'Capture Control') {
            getAttractions()
        }
    },[module])

    useEffect(() => {
        if (module && room && module === 'Controle de Acesso') {
            setQRCodeData({
                auth: `Basic ${btoa(`app@${event?.id}:app`)}`,
                module: module,
                moduleParams: {
                    event: event?.id,
                    room: room,
                    name: options?.find(room => room.id === room)?.room_name
                }
            })
        }
        if (module && room && module === 'Capture Control') {
            setQRCodeData({
                auth: `Basic ${btoa(`app@${event?.id}:app`)}`,
                module: module,
                moduleParams: {
                    event: event?.id,
                    attraction: room,
                    name: options?.find(attraction => attraction.id === room)?.name
                }
            })
        }
        if (module && module === 'CEK') {
            setQRCodeData({
                auth: `Basic ${btoa(`app@${event?.id}:app`)}`,
                module: module,
                moduleParams: {
                    event: event?.id,
                    name: "Controle de Brinde"
                }
            })
        }
    },[module, room])

    useEffect(() => {
        if(module === 'Autoatendimento' && printerIp) {
            setQRCodeData({
                auth: `Basic ${btoa(`tablet@${event?.id}:app`)}`,
                event: event?.id?.toString(),
                printerIp: printerIp,
                serverUrl: apiAddress
            })
        }
    },[module,printerIp,apiAddress])

    useEffect(() => {
        console.log(JSON.stringify(qrCodeData))
    },[qrCodeData])
    
    return (
    <>
        <TModal
            active={isActive}
            width='80vw'
            height='60vh'
            hideModal={() => undefined}
            footer={
                <>
                </>
            }
        >
            <ModalContainer>
                <a onClick={() => hideModal()}>
                    <CloseButton />
                </a>
                <Title>
                    Gerar QR Code de acesso ao app                    
                </Title>
                <ContentContainer>
                    <LabelContainer>
                        <label> Módulo </label>
                    </LabelContainer>
                    <InputContainer>
                        <Form.Select
                            aria-label='Default select example'
                            onChange={(e) => setModule(e.target.value)}
                        >
                            <option value={''}> -- Selecione -- </option>
                            {modules?.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Form.Select>
                    </InputContainer>
                    {module && module !== 'CEK' && module !== 'Autoatendimento' && <>
                        <LabelContainer>
                            <label> {module === 'Controle de Acesso'? 'Sala' : 'Atração'} </label>
                        </LabelContainer>
                        <Form.Select
                                aria-label='Default select example'
                                onChange={(e) => setRoom(Number(e.target.value))}
                        >
                                <option value={''}> -- Selecione -- </option>
                                {options?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                       {module === 'Controle de Acesso'? 
                                            `${item.room_name} - ${groups?.find(g => g.id === item.group)?.group_name}`
                                        :
                                            `${item.name}`
                                       }
                                    </option>
                                ))}
                            </Form.Select>
                    </>}
                    {module && module === 'Autoatendimento' && <>
                        <LabelContainer>
                            <label> Endereço da impressora </label>
                        </LabelContainer>
                        <Form.Control 
                            type='text'
                            value={printerIp}
                            onChange={(e) => setPrinterIp(e?.target?.value)}
                        />
                        <LabelContainer>
                            <label> Endereço da API </label>
                        </LabelContainer>
                        <Form.Control 
                            type='text'
                            value={apiAddress}
                            onChange={(e) => setApiAddress(e?.target?.value)}
                        />
                    </>}
                </ContentContainer>
                {qrCodeData &&
                    <QRCodeContainer>
                        <QRCode value={JSON.stringify(qrCodeData)} />
                        <FormButton onClick={handlePrintConfig}>
                            Imprimir QR Code
                        </FormButton>
                    </QRCodeContainer>
                }
            </ModalContainer>
        </TModal>
    </>
    );
}
