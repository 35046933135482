import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};

   

    .navbar {
        flex-grow: 1;
    }

    .navbar-brand {
        img {
            width: 100px;
            height: auto;
        }
    }

    .connectedUser {
        font-size: ${({ theme }) => theme.fontSize.sm};
        margin-left: 5px;
    }
`;

export const EmpTitle = styled.h4`
    font-size: ${({ theme }) => theme.fontSize.lg};
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors['gray-400']};
    font-weight: 400;

    margin: 0;
    padding: 8px 25px;
    cursor: pointer;
`;

export const NavBarContainer = styled.div``;

export const NavBarItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    > * {
        padding: 0px 20px;
    }

    > a.active:hover {
        text-shadow: 0px 0px 5px #ccc;
        color: #cccccc;
    }
`;

export const LogOutButton = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;

    border: none;
    background-color: transparent;
    cursor: pointer;

    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 600;
    text-decoration: none;

    gap: 2.5px;
    padding-right: 20px;

    p {
        margin: 0px;
        text-decoration: none;
    }

    :hover {
        > *,
        > svg {
            color: ${({ theme }) => theme.colors.steelblue};
            fill: ${({ theme }) => theme.colors.steelblue};
        }
    }
`;

export const NavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-600']};

    .navbar,
    .container,
    .navbar-brand {
        padding: 0;
    }

    .nav-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        text-decoration: none;
        font-size: ${({ theme }) => theme.fontSize.xs};
        color: ${({ theme }) => theme.colors['gray-700']};
        font-weight: 700;

        :hover {
            color: ${({ theme }) => theme.colors.steelblue};
        }
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    font-weight: 700;

    padding: 20px 0px 30px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-600']};
    border-top: 1px solid ${({ theme }) => theme.colors['gray-600']};
    margin: 0;
`;
