import { useToken } from '@/Components/UseToken';
import { EventResponse } from '@/Services/api/models/eventModel';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { GambetaContainer, SignInParticipantContainer, WarnText } from './styled';
import { BackArrow } from '@/Components/ArrowBack';
import ModalLoading from '@/Components/ModalLoading';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitButton } from '@/Pages/Events/Adicionar/styled';
import { TButton } from '@/Components/TButton';
import { useForm } from 'react-hook-form';
import ApiService from '@/Services/api/ApiService';
import { toast } from 'react-toastify';

export default function ResponderEnquete() {
    const location = useLocation();
    const { token } = useToken();
    const { eventID, participantID } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventInfos, setEventInfos] = useState<EventResponse>();
    const [backgroundImage, setBackgroundImage] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<any>({});

    const handleSubmitForm = async () => {

    }

    const handleGetEventInfos = async () => {
        try {
            setIsLoading(true);
            const event = await ApiService.getEventById(Number(eventID));
            setEventInfos(event)
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleGetBackgroundImage = async () => {
        try {
            setIsLoading(true);
            const image = await ApiService.getBackgroundImage(Number(eventID));
            setBackgroundImage(image)
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        handleGetBackgroundImage()
        handleGetEventInfos()
    },[])

  return (
        <GambetaContainer backgroundUrl={location.state?.backgroundImage ?? backgroundImage}>
            <SignInParticipantContainer
                backgroundUrl={location.state?.backgroundImage ?? backgroundImage}
            >
                <BackArrow />
                <ModalLoading isActive={isLoading} />
                <Form onSubmit={handleSubmit(handleSubmitForm)}>
                    <Form.Group as={Row} className='mb-3'>
                        <Form.Label column sm={2}>
                            Pergunta 1
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control {...register('nome')} type='text' />
                            {errors.nome?.message && <WarnText> '' </WarnText>}
                        </Col>
                    </Form.Group>   
                    <SubmitButton>
                        <TButton type='submit' width={'500px'}>
                            Enviar
                        </TButton>
                    </SubmitButton>
                </Form>
            </SignInParticipantContainer>
        </GambetaContainer>
  )
}
