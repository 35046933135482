import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

interface CheckInProps {
    backgroundUrl: string;
}

export const GambetaContainer = styled.div<CheckInProps>`
    width: 100vw;
    height: 120vh;

    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
`;

export const SignInParticipantContainer = styled.div<CheckInProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover; */

    width: 100vw;
    height: 100vh;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        max-width: 850px;

        padding: 50px 0px;
        border-radius: 25px;

        background: rgb(0 0 0 / 50%);
    }

    form > div {
        max-width: 700px;
        width: 80%;
    }

    .form-control:focus,
    .form-select:focus {
        border: 1px solid ${({ theme }) => theme.colors.steelblue} !important;
        box-shadow: none;
    }

    .form-control,
    .form-select {
        border-radius: 4px;

        color: ${({ theme }) => theme.colors['gray-700']};
    }

    .form-label {
        font-weight: 700;
        color: ${({ theme }) => theme.colors.white};
    }

    .form-label-gray {
        font-weight: 700;
        color: ${({ theme }) => theme.colors['gray-400']};
    }

    .form-check {
        float: none;
        > input {
            width: 17px;
            height: 17px;
            box-shadow: none;

            :checked {
                background-color: ${({ theme }) => theme.colors.steelblue};
            }
        }
    }
`;


export const WarnText = styled.p`
    font-weight: bolder;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors['red-500']};
    margin: 0;
    padding-top: 5px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 8vw;
`
