import axios, { AxiosError, AxiosResponse } from 'axios';
import { CreateEventRequest, EventResponse, FilterOptions, IMetrics } from '../api/models/eventModel';
import { CreateParticipantRequest, GetParticipantResponse } from '../api/models/participantsModel';
import { SignInRequest, SignInResponse } from '../api/models/signInModel';
import { CreateUserRequest, UsersResponse } from '../api/models/usersModel';
import { ParticipantCategories } from '../api/models/participantCategoryModel';
import { CekResponse, cekRequest } from '../api/models/cekModels';
import { MalexRequest, MalexResponse } from '../api/models/malexModels';
import { Attraction, AttractionParticipantCheckIn, AttractionRequest, AttractionSummary } from '../api/models/attractionsModels';
import { DefaultResponseRequest, DefaultResponseResponse, QuestionRequest, QuestionResponse, ResponseRequest, ResponseResponse } from '../api/models/surveyModels';

export const websocketURL = 'wss://controle-de-acesso-api.rentpass.com.br'
//export const websocketURL = 'ws://localhost:8000'

let configuration = {
    baseURL: 'https://controle-de-acesso-api.rentpass.com.br'
};

export default class ControleDeAcessoService {
    private static apiServiceAxios = axios.create(configuration);

    static loginData: { token?: string } = {
        token: undefined,
    };

    static setTokenUsuarioAutenticado(token: string) {
        this.apiServiceAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        this.loginData.token = token;
    }

    private static enviarRequestAsync = async <T>(
        verb: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        path: string,
        data: any = undefined,
        auth?: any,
    ): Promise<AxiosResponse<T, any>> => {

        try {
            switch (verb) {
                case 'GET':
                    return this.apiServiceAxios.get<T>(path, {
                        params: data,
                    });
                case 'PUT':
                case 'PATCH':
                case 'POST':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        url: path,
                        auth: auth,
                    });
                case 'DELETE':
                    return this.apiServiceAxios({
                        method: verb,
                        data: data,
                        url: path,
                    });

                default:
                    throw new Error('O verbo usado na requisição não é suportado pelo sistema.');
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response) {
                    // O servidor respondeu com um status code diferente de 200
                    if (error.response.data.erros.length > 0) {
                        throw new Error(error.response.data.erros[0].mensagem);
                    }
                    throw new Error('Ocorreu um erro ao processar sua solicitação.');
                } else if (error.request) {
                    // A requisição foi feita, porém não foi recebida nenhuma resposta do servidor
                    throw new Error('Não foi possível se comunicar com o servidor.');
                } else {
                    // Um erro ocorreu antes do envio da requisição
                    throw error;
                }
            } else {
                throw error;
            }
        }
    };

    public static async createRoom(data) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/create-room/`,
            data
        )
        return response.data;
    }

    public static async registerParticipant(data) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/register-participant/`,
            data
        )
        return response.data;
    }

    public static async editRoom(data) {
        const response = await this.enviarRequestAsync<any>(
            'PATCH',
            `/create-room/`,
            data
        )
        return response.data;
    }

    public static async getRoomsByEvent(eventID: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-all-rooms-by-event/${eventID}/`,
        )
        return response.data;
    }

    public static async getAllowedParticipantsByRoom(roomID: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-allowed-participants-by-room/${roomID}/`,
        )
        return response.data;
    }

    public static async getParticipantsByEvent(eventID: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-participants-by-event/${eventID}/`,
        )
        return response.data;
    }

    public static async getRoomById(roomID: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-room-by-id/${roomID}/`,
        )
        return response.data;
    }

    public static async getCheckInsByRoom(roomID: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-checked-in-participants-by-room/${roomID}/`,
        )
        return response.data;
    }

    public static async getCheckInsByEvent(eventID: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-checked-in-participants-by-event/${eventID}/`,
        )
        return response.data;
    }

    public static async checkInParticipant(data) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/check-in-participant/`,
            data
        )
        return response.data;
    } 
    
    public static async deleteRoom(data) {
        const response = await this.enviarRequestAsync<any>(
            'DELETE',
            `/delete-room/`,
            data
        )
        return response.data;
    } 

    public static async getGroupsByEvent(eventID: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-groups/${eventID}`,
        )
        return response.data;
    }

    public static async deleteGroup(data) {
        const response = await this.enviarRequestAsync<any>(
            'DELETE',
            `/delete-group/`,
            data
        )
        return response.data;
    } 

    public static async createGroup(data) {
        const response = await this.enviarRequestAsync<any>(
            'POST',
            `/create-group/`,
            data
        )
        return response.data;
    }

    public static async editGroup(data) {
        const response = await this.enviarRequestAsync<any>(
            'PATCH',
            `/create-group/`,
            data
        )
        return response.data;
    }

    public static async getGroupById(id: number) {
        const response = await this.enviarRequestAsync<any>(
            'GET',
            `/get-group-by-id/${id}/`,
        )
        return response.data;
    }

}
