export const toBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
        if (!file) {
            resolve('');
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result !== null) {
                resolve(reader.result.toString());
            } else {
                reject();
            }
        };

        reader.onerror = (error) => reject(error);
    });
