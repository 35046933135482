import styled from 'styled-components';

interface ModalContainerProps {
    width?: string;
    height?: string;
}

export const ModalBlock = styled.div`
    align-items: center;
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding: 0.4rem;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    opacity: 1;
    z-index: 400;
`;

export const CloseButton = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;
    top: 10px;
    left: -48%;
    z-index: 1;
`

export const ModalContainer = styled.div<ModalContainerProps>`
    position: fixed;
    top: 10vh;
    left: 10vw;
    height: 85vh;
    width: 80vw;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.8rem;
    animation: slide-down 0.2s ease 1;
    z-index: 1;
    box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
    gap: 10px;
`;

export const ModalBody = styled.div`
    overflow: hidden;
    padding: 30px 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
`;

export const CameraButton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    border: none;
    padding: 8px 12px;

    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.steelblue};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.sm};
    cursor: pointer;
    width: 100px;
    align-items: center;
    justify-content: center;
`