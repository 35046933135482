import { Background } from '@/Components/Background'
import { Header } from '@/Components/Header'
import ModalLoading from '@/Components/ModalLoading'
import React, { useEffect, useState } from 'react'
import { AddContainer, CekDashContainer, Chart, ChartsContainer, DynamicMenuContainer, FilterContainer, FilterFields, LeftSideContainer, RightSideContainer, WhiteBoxContainer } from './styled'
import { Form, Table } from 'react-bootstrap'
import { TableHeader } from '@/Models/CrudTableModel'
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import { Title } from '@/Components/Header/styled'
import { FormButton } from '@/Components/FormButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TButton } from '@/Components/TButton'
import { useToken } from '@/Components/UseToken'
import { CekResponse, cekRequest, participantCekList } from '@/Services/api/models/cekModels'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService from '@/Services/api/ApiService'
import { toast } from 'react-toastify'
import { IMetrics } from '@/Services/api/models/eventModel'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ModalCapture from '@/Components/ModalCapture'

interface FormValues {
    nameOrDocument: string
}

export default function CekDash() {
    const { token } = useToken();
    const { eventID } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [cekList, setCekList] = useState<CekResponse[]>()
    const [metrics, setMetrics] = useState<IMetrics>()
    const [filteredParticipants, setFilteredParticipants] = useState<participantCekList[]>([])
    const [selectedParticipant, setSelectedParticipant] = useState<Number[]>([])
    const [participantFilter, setParticipantFilter] = useState<string>('')
    const [captureModal, setCaptureModal] = useState<boolean>(false)
    const [mobile, setMobile] = useState<boolean>(false)

    useEffect(() => {
        if(window.innerWidth < 1200){
            setMobile(true)
        }
    },[])

    const chartData = [
        {name: 'Kits disponíveis', value: metrics?.kitQuantityLimit - metrics?.participantsCekWithdraw},
        {name: 'Kits retirados', value: metrics?.participantsCekWithdraw},
    ]


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
    } = useForm<FormValues>();

    const handleSubmitForm: SubmitHandler<FormValues> = async (data) => {
        const participant = cekList.find((participant) => participant.id?.toString() === data?.nameOrDocument) ?? cekList.find((participant) => participant?.documentNumber === data?.nameOrDocument)
        handleWithdraw(participant.id)
        setValue('nameOrDocument', '')
    }

    const handleSelectParticipants = (index: number) => {
        if(selectedParticipant.includes(index)) {
            const updatedSelectedParticipants = selectedParticipant.filter(item => item !== index);
            return setSelectedParticipant(updatedSelectedParticipants)
        }
        else{
            return setSelectedParticipant(arr => [...arr,index])
        }
    }

    const handleSelectAllParticipants = () => {
        if(selectedParticipant.length === filteredParticipants.length) {
            return setSelectedParticipant([])
        }
        else {
            return setSelectedParticipant(filteredParticipants.map((participant) => {return participant.id}));
        }
    }

    const FormatedColumns: TableHeader<any>[] = [
        {
            title: 'Nome',
            key: 'name',
        },
        {
            title: 'Sobrenome',
            key: 'lastName',
        },
        {
            title: 'Documento',
            key: 'documentNumber',
        },
        {
            title: 'Retirada',
            key: 'withdrawTimestamp',
        },
    ];

    const COLORS = [
        '#3366cc',
        '#dc3912',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
        '#316395',
        '#3366cc',
        '#994499',
        '#22aa99',
        '#aaaa11',
        '#6633cc',
        '#e67300',
        '#8b0707',
        '#651067',
        '#329262',
        '#5574a6',
        '#3b3eac',
        '#b77322',
        '#16d620',
        '#b91383',
        '#f4359e',
        '#9c5935',
        '#a9c413',
        '#2a778d',
        '#668d1c',
        '#bea413',
        '#0c5922',
        '#743411',
    ];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill='white'
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline='central'
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const handleGetCekList = async() => {
        try {
            setIsLoading(true);
            const list = await ApiService.getCekList(Number(eventID));
            const orderedList = list.sort((a,b) => Date.parse(a.cekWithdrawDate?.replace('T',' ')) - Date.parse(b.cekWithdrawDate?.replace("T", ' ')))
            const sortedList = list.sort((a, b) => {
                const dateA = a.cekWithdrawDate || '';
                const dateB = b.cekWithdrawDate || '';
                
                if (!dateA && !dateB) {
                  return 0;
                }

                if (!dateA) {
                  return 1;
                }
                if (!dateB) {
                  return -1;
                }
            
                const timestampA = Date.parse(dateA);
                const timestampB = Date.parse(dateB);
            
                if (timestampA < timestampB) {
                  return -1; 
                } else if (timestampA > timestampB) {
                  return 1; 
                }
                return 0; // dates are equal
              });
            setCekList(sortedList);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetMetrics = async() => {
        try {
            setIsLoading(true);
            const metrics = await ApiService.getEventMetrics(Number(eventID));
            setMetrics(metrics);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleWithdraw = async (id: number) => {
        try {
            const d = new Date();
            d.setHours(d.getHours() - 3);
            const request: cekRequest = {
                id: id,
                cekWithdrawDate: d.toISOString(),
                cekConfirmationType: 'WITHDRAW'
            }
            setIsLoading(true);
            await ApiService.cekWithdraw(request)
            toast.success('Retirada realizada')
        } catch (error: any) {
            if(error?.response?.data?.message === `Participante Id:${id} já realizou a retirada do KIT`) {
                return toast.error('Participante já realizou a retirada do Kit')
            }
        } finally {
            handleGetMetrics();
            handleGetCekList();
            setIsLoading(false);
        }
    }

    const handleCancelWithdraw = () => {
        selectedParticipant.map( async (participant) => {
            try {
                const d = new Date();
                d.setHours(d.getHours() - 3);
                const request: cekRequest = {
                    id: Number(participant),
                    cekWithdrawDate: d.toISOString(),
                    cekConfirmationType: 'CANCEL'
                }
                setIsLoading(true);
                await ApiService.cekWithdraw(request);
                toast.success('Cancelamento registrado')
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                handleGetMetrics();
                handleGetCekList();
                setIsLoading(false);
            }
        })
    }

    useEffect(() => {
        handleGetCekList()
        handleGetMetrics()
        checkModuleAvailability()
    },[])

    useEffect(() => {
        if(cekList?.length > 0) {
            const withdrawedParticipants = cekList.filter((participant) => participant.cekWithdrawDate !== null)
            if (participantFilter === '') {
                setFilteredParticipants(withdrawedParticipants)
            }
            else {
                setFilteredParticipants(withdrawedParticipants?.filter((participant) =>
                    `${participant.name}${participant.lastName}`.toLowerCase()
                        .includes(participantFilter.toLowerCase().replaceAll(' ', ''))
                    ||
                        `${participant.documentNumber}`.toLowerCase()
                        .includes(participantFilter.toLowerCase())
                ))
            }
        }
    },[cekList, participantFilter])

    const checkModuleAvailability = async () => {
        const event = await ApiService.getEventById(Number(eventID))
        if(!(event?.kitQuantityLimit > 0)) {
            navigate('/')
        }
    }

  return (
    <Background>
        <ModalLoading isActive={isLoading} />
        <ModalCapture isActive={captureModal} getResult={(value:string) => {handleSubmitForm({nameOrDocument: value})}} onClose={() => setCaptureModal(false)}/>
        <CekDashContainer>
            <Header pageTitle='Controle de Entrega de Kits' />
            <WhiteBoxContainer>
                <LeftSideContainer>
                    <AddContainer>
                        <Form onSubmit={handleSubmit(handleSubmitForm)}>
                            <Form.Label column>Registrar retirada</Form.Label>
                            <FilterFields>
                                {mobile
                                    ? <CameraAltIcon onClick={() => setCaptureModal(true)} />
                                    : <></>
                                }
                                <Form.Control
                                    type='text'
                                    autoFocus
                                    placeholder='Número do documento'
                                    {...register('nameOrDocument')}
                                    className='addField'
                                />
                                <TButton type='submit'>
                                    Adicionar
                                </TButton>
                            </FilterFields>
                        </Form>
                    </AddContainer>
                    <DynamicMenuContainer>
                        <FilterContainer>
                            {selectedParticipant.length === 0 
                                ?<FilterFields>
                                    <Form.Control
                                        type='text'
                                        placeholder='Filtrar participantes'
                                        onChange={(e) => setParticipantFilter(e.target.value)}
                                        className='addField'
                                    />
                                </FilterFields>
                                :<FilterFields>
                                    <FormButton 
                                        onClick={() => handleCancelWithdraw()}
                                    >
                                        Excluir Registros
                                    </FormButton>
                                </FilterFields>
                            }
                        </FilterContainer>
                    </DynamicMenuContainer>
                    <div className='tabela'>
                        <Table hover id='participantsTable'>
                            <thead>
                                <tr>
                                    {token.user.userGroup === 'ADMIN'?
                                        <th>
                                            <Form.Check
                                                type={'checkbox'}
                                                onChange={() => handleSelectAllParticipants()}
                                            />
                                        </th>
                                        :
                                        <></>
                                    }
                                    {FormatedColumns.map((column, index) => 
                                        <th key={index}>
                                            {column.title}
                                        </th>
                                    )}
                                </tr>
                                </thead> 
                                <tbody>
                                    {filteredParticipants.map((participant) => 
                                        <tr>
                                            {token.user.userGroup === 'ADMIN'? 
                                                <td>
                                                    <Form.Check
                                                        type={'checkbox'}
                                                        onChange={() => handleSelectParticipants(participant.id)}
                                                        checked={selectedParticipant.includes(participant.id)}
                                                    />
                                                </td>
                                                :
                                                <></>
                                            }
                                            <td>{participant.name}</td>
                                            <td>{participant.lastName}</td>
                                            <td>{participant.documentNumber}</td>
                                            <td>{participant.cekWithdrawDate}</td>
                                        </tr>
                                    )}
                                </tbody> 
                        </Table>
                    </div>
                </LeftSideContainer>
                <RightSideContainer>
                    <ChartsContainer>
                        <Chart>
                            <Title> Kits </Title>
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={chartData}
                                    cx='50%'
                                    cy='50%'
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill='#8884d8'
                                    dataKey='value'
                                >
                                    {chartData.map((_, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend layout='vertical' verticalAlign='bottom' />
                            </PieChart>
                        </Chart>
                        <Title> Total de Kits: {metrics?.kitQuantityLimit} </Title>
                    </ChartsContainer>
                </RightSideContainer>
            </WhiteBoxContainer>
        </CekDashContainer>
    </Background>
  )
}
