import styled from 'styled-components';
import { TButton } from '../TButton';

interface ButtonProps {
    backgroundColor: string;
}

export const ModalFooterButton = styled(TButton)<ButtonProps>`
    width: 50px;
    height: 50px;
    border-radius: 300px;
    background-color: ${(props) => props.backgroundColor};
`;

export const CenteredRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
`