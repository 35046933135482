import { TButton } from '@/Components/TButton';
import styled from 'styled-components';

export const ConfigureLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-width: 750px;

    gap: 15px;
    padding: 50px 0px;
`;

export const LabelButton = styled(TButton)`
    margin-bottom: 50px;
`;

export const ChartsContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    flex-wrap: wrap;
    gap: 25px;
`;

export const MetricsContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Chart = styled.div`
    text-align: center;
    border-left: 1px solid ${({ theme }) => theme.colors['gray-600']};
    border-right: 1px solid ${({ theme }) => theme.colors['gray-600']};
    padding: 10px
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
`
export const ReportBackground = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin-left: 30px;
    margin-right: 30px;
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.white};
`
export const Title = styled.h1`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    font-weight: 700;
    padding: 20px 0px 30px 0px;
    margin: 0;
`
