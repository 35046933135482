import { Background } from '@/Components/Background';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { Header } from '@/Components/Header';
import ModalLoading from '@/Components/ModalLoading';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import React, { useEffect, useState } from 'react'
import { ParticipantAddForm, SubmitButton, WarnText } from './styled';
import { Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TButton } from '@/Components/TButton';
import ApiService from '@/Services/api/ApiService';
import { Attraction, AttractionRequest } from '@/Services/api/models/attractionsModels';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface AttractionFormValues {
    nome: string;
    local: string;
    receptionUser: number;
    customerUser: number;
}

export default function AddAtracao() {
    const navigate = useNavigate()
    const { eventID } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [receptionUsers, setReceptionUsers] = useState<UsersResponse[]>()
    const [customerUsers, setCustomerUsers] = useState<UsersResponse[]>()

    function createYupSchema() {
        const schema = {
            nome: yup.string().required(),
            local: yup.string().required(),
        };
        
        return schema;

    }

    const validateSchema = yup.object().shape(createYupSchema());

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<AttractionFormValues>({
        resolver: yupResolver(validateSchema),
    });

    const handleSubmitForm = async (data: AttractionFormValues) => {
        try{
            setIsLoading(true)
            const createAttractionRequest:AttractionRequest = {
                name: data.nome,
                local: data.local,
                userId: data.customerUser,
                eventId: Number(eventID),
                receptionUserId: data.receptionUser,
            }
            const response = await ApiService.attractionUpInsert(createAttractionRequest)
            toast.success('Atração criada com sucesso')
            navigate(-1)
        } catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido');
        } finally {
            setIsLoading(false);
        }
    }

    const handleGetUserInfo = async () => {
        const data = await ApiService.getAllUsers()
        const receptionUsers = data.filter((user) => user.userGroup === 'ATTRACTION_RECEPTION' && user.events?.includes(Number(eventID)))
        console.log(receptionUsers)
        setReceptionUsers(receptionUsers)
        const customerUsers = data.filter((user) => user.userGroup === 'ATTRACTION' && user.events?.includes(Number(eventID)))
        setCustomerUsers(customerUsers)
    }


    
    useEffect(() => {
        handleGetUserInfo()
    },[])

  return (
    <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Capture Control' />
            <WhiteBackground>
                <ParticipantAddForm>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Nome
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('nome')} type='text' />
                                {errors.nome?.message && (
                                    <WarnText> {errors.nome.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Local
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('local')} type='text' />
                                {errors.nome?.message && (
                                    <WarnText> {errors.nome.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Cliente
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Select
                                    aria-label='Default select example'
                                    {...register('customerUser')}
                                >
                                    <option key={0} value={0}>--Selecione--</option>
                                    {customerUsers?.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {' '}
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                {errors.customerUser?.message && (
                                    <WarnText> {errors.customerUser.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Recepção
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Select
                                    aria-label='Default select example'
                                    {...register('receptionUser')}
                                >
                                    <option key={0} value={0}>--Selecione--</option>
                                    {receptionUsers?.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {' '}
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                {errors.receptionUser?.message && (
                                    <WarnText> {errors.receptionUser.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <SubmitButton>
                            <TButton type='submit'> Gravar </TButton>
                        </SubmitButton>
                    </Form>
                </ParticipantAddForm>
            </WhiteBackground>
        </Background>
  )
}
