import styled from 'styled-components';

export const GetEventosContainer = styled.div``;

export const WhiteBoxContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    padding: 20px;
    margin: 20px;

    .header {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 22px;
            font-size: ${({ theme }) => theme.fontSize.sm};
        };
        flex-direction: row;
        display: flex;
        gap: 10px;
    }



    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};

            > a:hover,
            > a:focus,
            > a:active {
                background-color: black;
            }

            > *:hover,
            > *:focus,
            > *:active {
                background-color: black;
            }
        }
    }
`;

export const StatusButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;
`;

export const SmallerColumn = styled.td`
    width: 10%;

    .active {
        background-color: ${({ theme }) => theme.colors.steelblue};
    }

    .inative {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }
`;

export const CapitalizedRow = styled.td`
    text-transform: capitalize;
`;
