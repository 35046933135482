export enum TranslateParticipantFields {
    'Nome' = 'Name',
    'Sobrenome' = 'Last Name',
    'Documento' = 'Document Number',
    'Estado' = 'State',
    'Cidade' = 'City',
    'Empresa' = 'Company',
    'Cargo' = 'Company Position',
    'TelCelular' = 'Cell Phone Number',
    'TelComercial' = 'Phone Number',
    'Email' = 'E-mail', 
    'Ativo' = 'Active',
    'Categoria' = 'Category'
}