import React from 'react';
import { ReactNode, InputHTMLAttributes } from 'react';
import { InputComponentContainer, StyledInput } from './styled';

export interface TInputRootProps {
    children: ReactNode;
}

function TnputRoot(props: TInputRootProps) {
    return <InputComponentContainer>{props.children}</InputComponentContainer>;
}

TnputRoot.displayName = 'TextInput.Root';

export interface TInputIconProps {
    children: ReactNode;
}

function TInputIcon(props: TInputIconProps) {
    return <>{props.children}</>;
}

TInputIcon.displayName = 'TextInput.Icon';

export interface TextInputInputProps extends InputHTMLAttributes<HTMLInputElement> {}

const TInputInput = React.forwardRef(
    (
        props: TextInputInputProps,
        ref?:
            | React.RefObject<HTMLInputElement>
            | ((instance: HTMLInputElement | null) => void)
            | null,
    ) => <StyledInput {...props} ref={ref} />,
);

TInputInput.displayName = 'TextInput.Input';

export const TextInput = {
    Root: TnputRoot,
    Input: TInputInput,
    Icon: TInputIcon,
};
