import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { useToken } from '@/Components/UseToken';

export const CESSReceptionRoute = (props: { children: React.ReactNode }) => {
    const { token } = useToken();

    if (!token) {
        return <Navigate to='/' replace />;
    }

    if (token.user.userGroup !== 'ADMIN' && token.user.userGroup !== 'PARTNER_ADMIN' && token.user.userGroup !== 'ATTRACTION_RECEPTION' && token.user.userGroup !== 'ATTRACTION') {
        return <Navigate to='/' replace />;
    }

    return <> {props.children} </>;
};