import { Background } from '@/Components/Background';
import ModalLoading from '@/Components/ModalLoading';
import React, { useEffect, useState } from 'react'
import { GetAttractionsContainer, Grow, MenuRow, SmallerColumn, StatusButton, WhiteBoxContainer } from './styled';
import { Header } from '@/Components/Header';
import { FormButton } from '@/Components/FormButton';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap';
import { Attraction } from '@/Services/api/models/attractionsModels';
import ApiService from '@/Services/api/ApiService';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { toast } from 'react-toastify';
import { Group, Room } from '@/Services/api/models/ControleDeAcessoModels';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { SignInResponse } from '@/Services/api/models/signInModel';
import { group } from 'console';

export default function Groups() {
    const navigate = useNavigate()
    const { eventID } = useParams()
    const [eventId, setEventId] = useState<string>()
    const [userInfo, setUserInfo] = useState<SignInResponse>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [groups, setGroups] = useState<Group[]>()
    const [groupsSelected, setGroupsSelected] = useState<Group[]>([])


    const getEventId = async () => {
        const userInfo = await JSON.parse(localStorage.getItem('token'))
        setUserInfo(userInfo)
        if (eventID) {
            setEventId(eventID)
        }
        else {
            const eventId = userInfo?.events?.[0]
            setEventId(eventId)
        }
    }

    const getEventGroups = async () => {
        setIsLoading(true)
        try{
            const data = await ControleDeAcessoService.getGroupsByEvent(Number(eventId))
            setGroups(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleSelectGroup = (group: Group) => {
        if(groupsSelected?.includes(group)) {
            setGroupsSelected(groupsSelected.filter(att => att !== group))
        }
        else {
            setGroupsSelected([...groupsSelected,group])
        }
    }

    const handleDeleteGroup = async (group: Group) => {
        setIsLoading(true)
        try{
            const request = {
                token: userInfo?.token,
                user: userInfo?.user?.id,
                id: group?.id
            }
            const response = await ControleDeAcessoService.deleteGroup(request)
            toast.success('Sala excluída')
            setGroupsSelected(groupsSelected.filter(att => att !== group))
            
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
            getEventGroups()
        }
    }

    const handleMassAction = async (f: Function) => {
        groupsSelected.map((group) => {
            f(group)
        })
    }

    useEffect(() => {
        if(eventId) {
            getEventGroups()
        }
    },[eventId])

    useEffect(() => {
        getEventId()
    },[])
    
  return (
    <Background>
            <ModalLoading isActive={isLoading} />
            <GetAttractionsContainer>
                <Header pageTitle='Controle de acesso' />
                <WhiteBoxContainer>
                    <MenuRow>
                        {userInfo?.user?.userGroup === 'ADMIN' && <>
                            <Grow>
                                <FormButton onClick={() => navigate(`/modulos/controle-de-acesso/grupos/adicionar/${eventId}`)}>
                                    Novo
                                </FormButton>
                            </Grow>
                            {groupsSelected.length > 0 &&
                                <Grow>
                                    <FormButton 
                                        className='bg-danger' 
                                        onClick={() => handleMassAction(handleDeleteGroup)}
                                    >
                                        Excluir Selecionados
                                    </FormButton>
                                </Grow>
                            }
                        </>}
                    </MenuRow>
                    <div className='body'></div>
                    <div className='tabela'>
                        {groups && (
                            <Table hover>
                                <thead>
                                    <tr>
                                        {userInfo?.user?.userGroup === 'ADMIN' &&
                                            <th></th>
                                        }
                                        <th></th>
                                        <th>Nome</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groups.map((group) => 
                                        <tr>
                                            {userInfo?.user?.userGroup === 'ADMIN' &&
                                                <SmallerColumn>
                                                    <Form.Check
                                                        type={'checkbox'}
                                                        onChange={() => handleSelectGroup(group)}
                                                        checked={groupsSelected?.includes(group)}
                                                    />
                                                </SmallerColumn>
                                            }
                                            <SmallerColumn>
                                                <StatusButton onClick={() => navigate(`/modulos/controle-de-acesso/grupos/editar/${group.id}`)}>
                                                    Editar
                                                </StatusButton>
                                            </SmallerColumn>
                                            <td>
                                                {group.group_name}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </WhiteBoxContainer>
            </GetAttractionsContainer>
        </Background>
  )
}
