import { SignInResponse } from '@/Services/api/models/signInModel';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { useState } from 'react';

export function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        if (!tokenString) {
            return null;
        }
        const userToken: SignInResponse = JSON.parse(tokenString);
        const userInfoString = localStorage.getItem('userInfo');
        if (!userInfoString) {
            return null;
        }
        const userInfo: UsersResponse = JSON.parse(userInfoString);
        return userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken: SignInResponse, userInfo: UsersResponse) => {
        localStorage.setItem('token', JSON.stringify(userToken));
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        setToken(userToken);
    };

    const clearToken = () => {
        localStorage.removeItem('token');
    };

    const setAdminEvent = (eventId: number) => {
        const token = getToken();
        if (!token) return;
        token.user.events = [eventId];
        localStorage.setItem('userInfo', JSON.stringify({events: [eventId]}))
        localStorage.setItem('token', JSON.stringify(token));
    };

    return {
        setToken: saveToken,
        clearToken,
        setAdminEvent,
        token,
    };
}
