import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
    CheckInContainer,
    CheckInForm,
    CheckInTitle,
    ErrorMessage,
    FormLabel,
    KeyboardContainer,
    PaymentContainer,
    QRCodeContainer,
    SearchByContainer,
    SkipButton,
    WelcomeMessage,
} from './styled';
import ModalLoading from '@/Components/ModalLoading';

import * as yup from 'yup';
import ApiService from '@/Services/api/ApiService';
import { useToken } from '@/Components/UseToken';
import { EventResponse, IEnglishParticipantSearchBy, IParticipantSearchBy } from '@/Services/api/models/eventModel';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import ModalNotificacaoError from '@/Components/ModalNotificacaoError';
import { useIdleTimer } from 'react-idle-timer';
import ModalConfirmIdentity from '@/Components/ModalConfirmIdentity';
import { handlePrintTicket } from '@/util/print';
import { FiArrowLeft } from 'react-icons/fi';
import { BackArrow } from '@/Components/ArrowBack';
import { useAutoAttendantNavigate } from '@/Components/CustomNavigate/autoattendant';
import TKeyboard from '@/Components/Keyboard';
import { UsersResponse } from '@/Services/api/models/usersModel';
import RsvpService from '@/Services/rsvp/RsvpService';
import QRCode from 'react-qr-code';
import NextInput from '@/Components/NextInput';
import FaceDetectionComponent from '@/Components/faceDetection/faceDetection';
import { TButton } from '@/Components/TButton';



interface CheckInFormValues {
    document: string;
}

export function FacialCheckIn() {
    const location = useLocation();
    const faceReconRef = useRef(null)
    const [startTimestamp, setStartTimestamp] = useState<Date>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [participantFound, setParticipantFound] = useState<GetParticipantResponse>();
    const [backgroundImage, setBackgroundImage] = useState('');
    const [eventInfos, setEventInfos] = useState<EventResponse>();
    const [paymentQRCode, setPaymentQRCode] = useState<string>()
    const [orderId, setOrderId] = useState<number>()
    const [success, setSuccess] = useState<boolean>(false)

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const [errorOnPrint, setErrorOnPrint] = useState(false);

    const [showIdentityModal, setShowIdentityModal] = useState(false);

    const { language } = useParams()
    const [english, setEnglish] = useState<boolean>(false)

    const navigate = useAutoAttendantNavigate(location.state?.backgroundImage ?? backgroundImage);

    const userInfoString = localStorage.getItem('userInfo');
    const userInfo: UsersResponse = JSON.parse(userInfoString) ?? {}

    useIdleTimer({
        timeout: 60000,
        onIdle: () => {
            if (!errorOnPrint) navigate('/autoattendant/');
        },
    });

    const onConfirmCheckout = async () => {
        try {
            setIsLoading(true);
            if (!participantFound) {
                throw new Error(english? 'No participant found' : 'Não encontramos nenhum participante');
            }

            if (!eventInfos) {
                throw new Error(english? 'No events linked to this participant' : 'Nenhum evento vinculado a esse participante');
            }

            if (participantFound) {
                if(participantFound.categoryId !== 1){
                    throw new Error(english? 'Please look for the reception to check in' : 'Procure a recepção para fazer o seu check-in')
                }
            }

            if (userInfo?.additionalCategory) {
                if(participantFound?.additionalCategory !== userInfo?.additionalCategory) {
                    throw new Error(
                        `Por favor, procure um totem para a categoria ${participantFound?.additionalCategory}`
                    )
                }
            }

            const d = new Date();
            const endTimestamp =  d.getTime() - startTimestamp.getTime()
            d.setHours(d.getHours() - 3);
            if(participantFound.documentNumber !== '00000000000') {
                await ApiService.checkInParticipant(participantFound.id, d.toISOString(), endTimestamp)
            }
            if(!eventInfos?.surveyControl) {
                if(eventInfos.labelConfiguration !== 'No label') { await handlePrintTicket(eventInfos, participantFound) };
                setShowModal(true);
                setSuccess(true)
            }
            else {
                const questions = await ApiService.getQuestionsByEventId(eventInfos?.id)
                const checkInQuestions = questions.filter((question) => question.checkinQuestion === true)
                if(checkInQuestions?.length > 0) {
                    navigate(`/questoes/${eventInfos.id}/${participantFound.id}`)   
                }
                else {
                    if(eventInfos.labelConfiguration !== 'No label') { await handlePrintTicket(eventInfos, participantFound) };
                    setShowModal(true);
                    setSuccess(true);
                }
            }
        } catch (e: any) {
            setShowModal(true);
            if((e?.response?.data?.message)?.toString()?.includes('CheckIn já realizado para o participante')) {
                setModalMessage(english? 'Participant has already checked in' : 'CheckIn já realizado')
            }
            else {
                setModalMessage(e.message);
            }
            setErrorOnPrint(true);
        } finally {
            setIsLoading(false)
            setTimeout(() => {
                navigate('/autoattendant/');
            }, 4000);
        }
    };

    const getEventInfo = async () => {
        try {
            setIsLoading(true);
            const event = await ApiService.getEventById(userInfo?.events?.[0] ?? 0);
            setEventInfos(event);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadEventImage = async () => {
        try {
            setIsLoading(true);
            const backgroundImage = await ApiService.getBackgroundImage(userInfo?.events?.[0] ?? 0);
            setBackgroundImage(backgroundImage);
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const identifyParticipant = async (img: any) => {
        /*const response = await fetch('https://gateway.apifacialid.com.br/wsfacecid/v2/identify',{
            method: 'POST',
            headers: {
              'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF9jbGllbnRlIjo2MzEsImlkX2NsaWVudGVfdGFnZWQiOjY4MSwiZW1haWwiOiJ0aGlhZ29AbmV4dGxldmVsY29kZS5jb20uYnIiLCJ0YWdzIjpbIlRSSUFMIl19.LuI5Z5y34Ye9Kw7kZBlNZiocEibn7_5nZZOZFw-ojio`,
              "Content-type": "application/json"
            },
            body: JSON.stringify({
              "fotob64": img,
              "skipIdentification": false
            })
        })*/
       const response = await fetch(`https://8adb-179-113-23-105.ngrok-free.app/verify/${eventInfos?.id}/`,{
            method: 'POST',
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({
                "img": img
            })
       })
        if(response.ok) {
            const data = await response.json()
            const participant = await ApiService.getParticipantByID(data)
            if(participant) {
                setParticipantFound(participant)
            }
        } else {
            setModalMessage('Não foi possível te identificar, vamos tentar outro método.')
            navigate('/autoattendant/checkin/pt')
        }
    }

    const skipToCheckIn = async () => {
        navigate('/autoattendant/checkin/pt')
    }


    useEffect(() => {
        setStartTimestamp(new Date())
        getEventInfo();
        loadEventImage();
    }, []);


    useEffect(() => {
        setEnglish(language === 'en')
    },[])


    useEffect(() => {
        if(!paymentQRCode) {
            if(participantFound) {
                eventInfos.checkinType === "CONFIRMATION_REQUIRED"? setShowIdentityModal(true) : onConfirmCheckout();
            }
        }
        else {
            const interval = setInterval(async () => {
                const response = await RsvpService.checkPaymentStatus(orderId)
                if (response.status === 'PAID') {
                    setPaymentQRCode(undefined)
                }
            },2000)

            return (() => clearInterval(interval))
        }

    },[participantFound, paymentQRCode])


    /*useEffect(() => {
        // Function to load the external script dynamically
        const loadScript = (src: string, callback: ((this: GlobalEventHandlers, ev: Event) => any) | null) => {
          const script = document.createElement("script");
          script.src = src;
          script.defer = true;
          script.onload = callback;
          document.body.appendChild(script);
        };
    
        // Callback after script is loaded to initialize FaceRecon
        const initializeFaceRecon = () => {
          const CID = (window as any).CID;
          if (CID && CID.FaceRecon) {
            const faceRecon = new CID.FaceRecon({
              onDetection: (img: any) => identifyParticipant(img),
              element: faceReconRef.current,
              tolerance: 90,
              automaticDetection: true,
              timeToWait: null,
              playSize: 1,
            });
            faceRecon.run();
          }
        };
    
        // Load the external script and initialize after it has loaded
        loadScript("https://dev-front.apifacialid.com.br/facerecon/facerecon.js?v=1.1.1", initializeFaceRecon);
    
        // Cleanup the script when the component is unmounted
        return () => {
          const script = document.querySelector(`script[src="https://dev-front.apifacialid.com.br/facerecon/facerecon.js?v=1.1.1"]`);
          if (script) {
            document.body.removeChild(script);
          }
        };
    }, []);*/


    return (
        <CheckInContainer backgroundUrl={location.state.backgroundImage ?? backgroundImage}>
            <ModalLoading isActive={isLoading} />
            <ModalConfirmIdentity
                hideModal={() => setShowIdentityModal(false)}
                isActive={showIdentityModal}
                onButtonClick={() => {
                    setShowIdentityModal(false);
                    onConfirmCheckout();
                }}
                participant={participantFound}
            />
            <BackArrow />
            <SearchByContainer>
                {showModal?
                    paymentQRCode?
                        <PaymentContainer>
                            <p>{english? 'Scan the QR code and complete payment on your phone' : 'Escaneie o QR code abaixo e realize o pagamento'}</p>
                            <QRCodeContainer>
                                <QRCode
                                    size={200}
                                    value={paymentQRCode}
                                    viewBox={`0 0 200 200`}
                                    level='M'
                                />
                            </QRCodeContainer>
                        </PaymentContainer>
                    :
                        success? 
                        <PaymentContainer>
                            <WelcomeMessage>
                                {`Bem-vindo, ${participantFound?.name}!`}
                            </WelcomeMessage>
                            {eventInfos?.welcomeMessage &&
                                <WelcomeMessage>
                                    {eventInfos?.welcomeMessage}
                                </WelcomeMessage>
                            }
                        </PaymentContainer>
                        :
                            <ErrorMessage>
                                {modalMessage}
                            </ErrorMessage>
                : 
                    <>
                        <CheckInTitle>
                            {' '}
                            {eventInfos?.removeTitleLabel && eventInfos?.description}{' '}
                        </CheckInTitle>
                        <FaceDetectionComponent onDetection={(img: any) => identifyParticipant(img)} />
                        {/*<div id="elementoOndeFaceReconSeraInstanciado" ref={faceReconRef} />*/}
                        <SkipButton backgroundColor={eventInfos?.buttonColor} onClick={skipToCheckIn}>Usar outra forma de identificação</SkipButton>
                    </>
                }
            </SearchByContainer>
        </CheckInContainer>
    );
}
