import styled from 'styled-components';

interface ModalContainerProps {
    width?: string;
    height?: string;
}

export const ModalBlock = styled.div`
    align-items: center;
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding: 0.4rem;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    opacity: 1;
    z-index: 400;
`;

export const ModalContainer = styled.div<ModalContainerProps>`
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-height: ${({ height }) => (height ? height : '75vh')};
    max-width: 100%;
    width: ${({ width }) => (width ? width : '100%')};
`;

export const ModalBody = styled.div`
    overflow: hidden;
    padding: 30px 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
`;
