import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import { Box, BoxesContainer, Title, Row, WarnText, Column, ButtonContainer, StatusButton, SmallerColumn } from './styled';

import Totem_ConfigurandoEvento from './docs/1_Totem_ConfigurandoEvento.docx';
import Totem_ConfigurandoImpressora from './docs/2_Totem_ConfigurandoImpressora.docx';
import { Step } from '../Instalação/Step';
import { TextInput } from '@/Components/TInput';
import { useEffect, useState } from 'react';
import { TButton } from '@/Components/TButton';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import ApiService from '@/Services/api/ApiService';
import { Table } from 'react-bootstrap';

export function Configurations() {
    const [newCategory, setNewCategory] = useState<string>('')
    const [categories, setCategories] = useState<Array<ParticipantCategories>>([])
    const [updateList, setUpdateList] = useState<number>(0)

    const handleAddCategory = () => {
        const ids = categories.map((category) => {
            return category.id
        })
        const newId = Math.max(...ids) + 1
        ApiService.insertParticipantCategory(
            newCategory,
            newId
        )
        .then((response) => setUpdateList(n => n+1))
    }

    const handleRemoveCategory = (id: number) => {
        ApiService.deleteParticipantCategory(id)
        .then((response) => setUpdateList(n => n+1))
    }
    
    useEffect(() => {
        ApiService.getParticipantCategories()
        .then((data) => setCategories(data))
    },[updateList])

    return (
        <Background>
            <Header pageTitle='Configurações do Sistema' />
            <BoxesContainer>
                <Box>
                    <Title> Categorias de participantes </Title>
                    <Row>
                        <TextInput.Root>
                            <TextInput.Input
                                onChange={(e) => setNewCategory(e.target.value)}
                                placeholder='Categoria'
                            />
                        </TextInput.Root>
                        <ButtonContainer>
                            <TButton onClick={() => handleAddCategory()}>Adicionar</TButton>
                        </ButtonContainer>
                    </Row>
                    <Table>
                        <tr>
                            <th><Column>Nome</Column></th>
                            <th></th>
                        </tr>
                        {categories.map((category) => 
                            <tr>
                                <td><Column>{category.categoryName}</Column></td>
                                <td>
                                    <SmallerColumn>
                                        <StatusButton className={'red'} onClick={() => handleRemoveCategory(category.id)}>
                                            Excluir
                                        </StatusButton>
                                    </SmallerColumn>
                                </td>
                            </tr>
                        )}
                    </Table>
                </Box>
            </BoxesContainer>
        </Background>
    );
}
