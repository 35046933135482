import React, { Fragment } from 'react';
import {
    ModalBlock,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalTitle,
} from './styled';

interface Props {
    title?: string | React.ReactNode;
    footer: React.ReactNode;
    active: boolean;
    hideModal: () => void;
    children: React.ReactNode;
    width?: string;
    height?: string;
}

const Modal = ({ title, footer, children, active, hideModal, width, height }: Props) => {
    return (
        <Fragment>
            {active && (
                <ModalBlock>
                    <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
                    <ModalContainer width={width} height={height}>
                        {title && (
                            <ModalHeader>
                                <ModalTitle>{title}</ModalTitle>
                            </ModalHeader>
                        )}
                        <ModalBody>{children}</ModalBody>
                        <ModalFooter>{footer}</ModalFooter>
                    </ModalContainer>
                </ModalBlock>
            )}
        </Fragment>
    );
};
export default Modal;
