import styled from 'styled-components';

export const ModalContainer = styled.div`
    padding: 2vh;
    justify-content: center;
    display: flex;
    flex-direction: column
`

export const Text = styled.p`
    font-size: ${({ theme }) => theme.fontSize.form};
`

export const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: 500;

    font-size: ${({ theme }) => theme.fontSize.form};

    width: 100%;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
    }

    form > div {
        max-width: 700px;
        width: 80%;
    }

    .form-control:focus {
        border: 1px solid ${({ theme }) => theme.colors.steelblue} !important;
        box-shadow: none;
    }

    .form-control {
        border-radius: 4px;

        color: ${({ theme }) => theme.colors['gray-700']};
    }

    .form-label {
        font-weight: 700;
        font-color: ${({ theme }) => theme.colors['gray-700']};
    }

    .form-check {
        float: none;

        > input {
            width: 17px;
            height: 17px;
            box-shadow: none;

            :checked {
                background-color: ${({ theme }) => theme.colors.steelblue};
            }
        }
    }

    .checkboxSection {
        > * {
            margin-bottom: 10px;
            font-weight: 200;
        }
    }
`;

export const Input = styled.input.attrs({ className: 'form-control' })`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSize.form};
  color: ${({ theme }) => theme.colors['gray-700']};
  padding: 8px 16px;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.steelblue};
    box-shadow: none;
  }
`;


export const StyledCol = styled.col`
  display: flex;
  flex-direction: row;
  align-items: center
`

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2vw;
  margin-bottom: 2vh;
`
export const ItemRow = styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;
align-items: center;
`
export const RedText = styled.h6`
  color: ${({ theme }) => theme.colors['red-500']};
  cursor: pointer;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: 4vh;
`;

export const SubmitButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 200px;
        height: 50px;
    }
`;

