import styled from 'styled-components';

export const StepContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    gap: 5px;
`;

export const Dot = styled.div`
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors['gray-700']};

    width: 10px;
    height: 10px;
`;
