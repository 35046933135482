import styled from 'styled-components';

export const WhiteBackground = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
    background-color: ${({ theme }) => theme.colors.white};
`;
