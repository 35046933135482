import { Dot, StepContainer } from './styled';

interface IStepProps {
    content: React.ReactElement | string;
}

export function Step(props: IStepProps) {
    return (
        <StepContainer>
            <Dot />
            {props.content}
        </StepContainer>
    );
}
