import styled from 'styled-components';

export const ParticipantAddForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: 500;

    font-size: ${({ theme }) => theme.fontSize.form};
    margin: 20px;

    width: 100%;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
    }

    form > div {
        max-width: 700px;
        width: 80%;
    }

    .form-control:focus,
    .form-select:focus {
        border: 1px solid ${({ theme }) => theme.colors.steelblue} !important;
        box-shadow: none;
    }

    .form-control,
    .form-select {
        border-radius: 4px;

        color: ${({ theme }) => theme.colors['gray-700']};
    }

    .form-label {
        font-weight: 700;
        color: ${({ theme }) => theme.colors['gray-700']};
    }

    .form-check {
        float: none;
        > input {
            width: 17px;
            height: 17px;
            box-shadow: none;

            :checked {
                background-color: ${({ theme }) => theme.colors.steelblue};
            }
        }
    }

    .checkboxSection {
        > * {
            margin-bottom: 10px;
            font-weight: 200;
        }
    }
`;

export const SubmitButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;

    > button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 70px;
        height: 39px;
    }
`;

export const DashedHR = styled.hr`
    width: 95%;

    border-top: 1px dashed black;
    height: 1px;
    margin: 20px 0;
`;

export const WarnText = styled.p`
    font-weight: bolder;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors['red-500']};
    margin: 0;
    padding-top: 5px;
`;

export const CapitalizedRow = styled.td`
    text-transform: capitalize;
`;


export const ImagePreview = styled.img`
    width: 250px;
    height: 100px;
    margin: 10px 0px 20px 0px;
`;

export const Bolder = styled.div`
    font-weight: bold;
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: 4vh;
`;

export const Input = styled.input.attrs({ className: 'form-control' })`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors['gray-400']};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSize.form};
  color: ${({ theme }) => theme.colors['gray-700']};
  padding: 8px 16px;
  outline: none;
  transition: all 0.3s ease;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.steelblue};
    box-shadow: none;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2vw;
  margin-bottom: 2vh;
`
export const ItemRow = styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;
align-items: center;
`

export const LinkText = styled.h6`
  color: ${({ theme }) => theme.colors.steelblue};
  cursor: pointer;
`
export const RedText = styled.h6`
  color: ${({ theme }) => theme.colors['red-500']};
  cursor: pointer;
`