import styled from 'styled-components';

export const SignInContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 350px;

    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    padding: 20px;
    margin-top: 10vh;
    gap: 10px;
`;

export const SignInForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    button {
        width: 100%;
    }

    > * {
        margin-bottom: 0.8rem;
    }
`;

export const SignInTitle = styled.h3`
    margin: 0px;
    font-size: ${({ theme }) => theme.fontSize.base};
    color: ${({ theme }) => theme.colors['gray-700']};
`;

export const SignInSubTitle = styled.p`
    margin: 0px;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors['gray-700']};
`;

export const WarnText = styled.p`
    font-weight: bolder;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors['red-500']};
    align-text: center;
`;

export const LoginBackground = styled.div`
    position: absolute;

    width: 100vw;
    height: 100vh;

    background-image: url('/assets/images/loginBg.png');
    background-repeat: no-repeat;
    background-position: center bottom;
`;
