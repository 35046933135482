import { Background } from '@/Components/Background';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { Header } from '@/Components/Header';
import ModalLoading from '@/Components/ModalLoading';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import React, { useEffect, useState } from 'react'
import { ParticipantAddForm, SubmitButton, WarnText } from './styled';
import { Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TButton } from '@/Components/TButton';
import ApiService from '@/Services/api/ApiService';
import { Attraction, AttractionRequest } from '@/Services/api/models/attractionsModels';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SignInResponse } from '@/Services/api/models/signInModel';
import { EventResponse } from '@/Services/api/models/eventModel';
import ControleDeAcessoService from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { Group, GroupRequest } from '@/Services/api/models/ControleDeAcessoModels';

export interface GroupFormValues {
    group_name: string;
}

export default function AddGrupo() {
    const navigate = useNavigate()
    const { eventID, groupID } = useParams()
    const [groupToEdit, setGroupToEdit] = useState<Group>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [userData, setUserData] = useState<SignInResponse>()
    const [currentEvent, setCurrentEvent] = useState<EventResponse>()


    function createYupSchema() {
        const schema = {
            group_name: yup.string().required(),
        };
        
        return schema;

    }

    const validateSchema = yup.object().shape(createYupSchema());

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<GroupFormValues>({
        resolver: yupResolver(validateSchema),
    });

    const handleSubmitForm = async (data: GroupFormValues) => {
        try{
            setIsLoading(true)
            const createGroupRequest:GroupRequest = {
                group_name: data.group_name,
                rentpass_event: Number(eventID),
                user: userData?.user?.id,
                token: userData?.token
            }
            if(groupToEdit) {
                createGroupRequest.id = groupToEdit.id
                createGroupRequest.rentpass_event = groupToEdit.rentpass_event
                const response = await ControleDeAcessoService.editGroup(createGroupRequest)
            }
            else {
                const response = await ControleDeAcessoService.createGroup(createGroupRequest)
            }
            
            toast.success('Grupo criado com sucesso')
            navigate(-1)
        } catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido');
        } finally {
            setIsLoading(false);
        }
    }

    const handleGetUserInfo = async () => {
        const data = await JSON.parse(localStorage?.getItem('token') ?? '')
        setUserData(data)
    }

    const handleGetGroupToEdit = async (id: number) => {
        setIsLoading(true)
        try{
            const group = await ControleDeAcessoService.getGroupById(id)
            setGroupToEdit(group)
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }

    }
    
    useEffect(() => {
        handleGetUserInfo()
    },[])

    useEffect(() => {
        if(groupID) {
            handleGetGroupToEdit(Number(groupID))
        }
    },[])

    useEffect(() => {
        if(groupToEdit) {
            setValue('group_name', groupToEdit.group_name)
        }
    },[groupToEdit])

  return (
    <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Capture Control' />
            <WhiteBackground>
                <ParticipantAddForm>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                Nome
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('group_name')} type='text' />
                                {errors.group_name?.message && (
                                    <WarnText> {errors.group_name.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <SubmitButton>
                            <TButton type='submit'> Gravar </TButton>
                        </SubmitButton>
                    </Form>
                </ParticipantAddForm>
            </WhiteBackground>
        </Background>
  )
}
