import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CenteredRow, ModalFooterButton } from './styled';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

interface ConfirmDeleteModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void> | void;
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    return (
        <>
            <Modal show={props.show} onHide={props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja excluir?</Modal.Body>
                <Modal.Footer>
                    <CenteredRow>
                        <ModalFooterButton onClick={props.onClose} backgroundColor='red'>
                            <ThumbDownIcon />
                        </ModalFooterButton>
                        <ModalFooterButton onClick={props.onConfirm} backgroundColor='green'>
                            <ThumbUpIcon />
                        </ModalFooterButton>
                    </CenteredRow>
                </Modal.Footer>
            </Modal>
        </>
    );
}
