import { Dropdown, DropdownButton } from 'react-bootstrap';
import styled from 'styled-components';

export const GetParticipantsContainer = styled.div``;

export const ImportExcel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSize.xs};
        cursor: pointer;

        align-items: center;
        height: 100%;
    }
`;

export const EventTitle = styled.div`
    span {
        font-weight: bold;
    }

    margin: 20px 0px 5px 20px;
`;

export const WhiteBoxContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};

    padding: 20px;
    margin: 0px 20px 20px 20px;

    table {
        min-height: 150px;
    }

    input[type='file'] {
        display: none;
    }

    .header {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin-bottom: 15px;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: ${({ theme }) => theme.fontSize.xs};
        }
    }

    .body {
        display: flex;
        flex-direction: row;
    }

    .tabela {
        overflow: auto;
        padding-bottom: 50px;
    }

    .highlighted {
        background-color: lightpink;
    }

    th {
        font-weight: 700;
    }

    td,
    th {
        font-size: ${({ theme }) => theme.fontSize.form};
    }

    .dropdown {
        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: white;
            height: 22px;
            width: 50px;
            font-size: ${({ theme }) => theme.fontSize.xs};

            :hover {
                color: white;
            }
        }

        .dropdown-item {
            font-size: ${({ theme }) => theme.fontSize.xs};
            color: ${({ theme }) => theme.colors['gray-700']};

            > a:hover,
            > a:focus,
            > a:active {
                background-color: black;
            }

            > *:hover,
            > *:focus,
            > *:active {
                background-color: black;
            }
        }
    }
`;

export const StatusButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;
`;

export const SmallerColumn = styled.td`
    width: 8%;

    .active {
        background-color: ${({ theme }) => theme.colors.steelblue};
    }

    .inative {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }
    .action {
        background-color: #ffc107;
    }
`;

export const DateColumn = styled.td`
    min-width: 140px;
`;

export const DivergencyTitle = styled.h3`
    text-transform: uppercase;

    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: bolder;
    color: ${({ theme }) => theme.colors['red-500']};
    text-align: center;
`;

export const DivergencyItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .message {
        font-size: ${({ theme }) => theme.fontSize.sm};
    }
`;

export const NotificationCircle = styled.div`
    background-color: ${({ theme }) => theme.colors['pink-500']};
    border-radius: 50%;

    width: 10px;
    height: 10px;

    margin-right: 10px;
`;

export const CapitalizedRow = styled.td`
    text-transform: capitalize;
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin: 10px 0px;
`;

export const FilterFields = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 40vw;
    align-items: center;
`;

export const Grow = styled.div`
    display: flex;
    flex-grow: 1;

    gap: 15px;
`;

export const GrowRight = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 15px;
`;

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
    min-width: 120px;
    padding-left: 2vw;
    padding-right: 2vw;
    @media screen and (max-width: 705px) {
        min-height: 6vw; 
    }
`

export const CustomLabel = styled.label`
    border: none;
    padding: 1px 8px;

    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.steelblue};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.sm};

    cursor: pointer;
`
export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
`

export const YellowFormButton = styled.button`
    border: none;
    padding: 1px 8px;
    border-radius: 4px;
    background-color: #ffc107;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.sm};

    cursor: pointer;
`;
