import { FiArrowLeft } from 'react-icons/fi';
import { BackArrowContainer } from './styled';
import { useNavigate } from 'react-router-dom';

export function BackArrow() {
    const navigate = useNavigate();

    return (
        <BackArrowContainer onClick={() => navigate(-1)}>
            <FiArrowLeft size={25} />
        </BackArrowContainer>
    );
}
