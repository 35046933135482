import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import { TButton } from '@/Components/TButton';
import { Col, Form, ListGroup, Row } from 'react-bootstrap';
import { DashedHR, ItemRow, LinkText, ParticipantAddForm, RedText, StyledRow, SubmitButton, WarnText } from './styled';

import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import ApiService from '@/Services/api/ApiService';

import { SubmitHandler } from 'react-hook-form';
import ModalLoading from '@/Components/ModalLoading';

import 'react-datepicker/dist/react-datepicker.css';
import {
    CreateParticipantRequest,
    GetParticipantResponse,
} from '@/Services/api/models/participantsModel';

import EstadosBrasileiros from '@/util/estadosBrasileiros.json';
import { useToken } from '@/Components/UseToken';
import { toast } from 'react-toastify';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import { ConfirmModal } from '@/Components/ConfirmModal';
import { EventResponse } from '@/Services/api/models/eventModel';
import { handlePrintTicket } from '@/util/print';
import { ParticipantCategories } from '@/Services/api/models/participantCategoryModel';
import QuestionOptionsModal from '@/Components/questionOptionsModal';
import { DefaultResponseRequest, QuestionRequest, QuestionResponse } from '@/Services/api/models/surveyModels';

export interface ParticipantFormValues {
    "allowCustomResponse": boolean
    "checkinQuestion": boolean
    "description": string
    "eventId": number
    "multiplesChoices": boolean
    "optional": boolean
    "id": number
}


export function ConfigureEnquete() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionResponse>()
    const [currentEvent, setCurrentEvent] = useState<EventResponse>();
    const [questions, setQuestions] = useState<QuestionResponse[]>()
    const { eventID, userID } = useParams();
    const { token } = useToken();


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch
    } = useForm<ParticipantFormValues>();


    const handleGetEventByID = async (id: number) => {
        ApiService.getEventById(id)
        .then((response) => console.log(response))
        try {
            setIsLoading(true);
            const events = await ApiService.getEventById(id);
            setCurrentEvent(events);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleQuestionsByEvent = async () => {
        const response = await ApiService.getQuestionsByEventId(Number(eventID))
        setQuestions(response)
    }

    const handleSubmitForm: SubmitHandler<ParticipantFormValues> = async (data) => {
        try {
            setIsLoading(true);
            const response = await ApiService.insertQuestion(data)
            handleQuestionsByEvent()
        } catch (e: any) {
            toast.error(e.response.data.message ?? 'Erro desconhecido');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteQuestion = async (data: QuestionResponse) => {
        try {
            setIsLoading(true);
            const response = await ApiService.deleteQuestion(data?.id)
            handleQuestionsByEvent()
        } catch (e: any) {
            toast.error(e.response.data.message ?? 'Erro desconhecido');
        } finally {
            setIsLoading(false);
        }
    }

    const handleAddAnswer = async (data: string[]) => {
        let request:DefaultResponseRequest[] = []
        data?.map((question) => {
            request.push({
                'id': 0,
                'questionId': selectedQuestion?.id,
                'description': question
            })
        })
        const response = await ApiService.bulkInsertDefaultResponse(request)
        setSelectedQuestion(undefined)
    }


    useEffect(() => {
        setValue('eventId', Number(eventID))
        setValue('id', 0)
        handleGetEventByID(Number(eventID))
        handleQuestionsByEvent()
    },[eventID])

    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Configurar Perguntas Adicionais' />
            <QuestionOptionsModal show={selectedQuestion ? true : false} question={selectedQuestion} saveCallback={(data) => handleAddAnswer(data)}/>
            <WhiteBackground>
                <ParticipantAddForm>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column>Título da Pergunta</Form.Label>
                            <StyledRow>
                                <Form.Control {...register('description')}/>
                                    {errors.description?.message && (
                                        <WarnText> {errors.description.message} </WarnText>
                                    )}
                            </StyledRow>
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Check {...register('multiplesChoices')} label={'Múltipla escolha'}/>
                            {errors.multiplesChoices?.message && (
                                <WarnText> {errors.multiplesChoices.message} </WarnText>
                            )}
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Check {...register('checkinQuestion')} label={'Mostrar pergunta no check-in'}/>
                            {errors.checkinQuestion?.message && (
                                <WarnText> {errors.checkinQuestion.message} </WarnText>
                            )}
                        </Form.Group>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Check {...register('optional')} label={'Opcional'}/>
                            {errors.optional?.message && (
                                <WarnText> {errors.optional.message} </WarnText>
                            )}
                        </Form.Group>
                        <SubmitButton>
                            <TButton type='submit'> Adicionar </TButton>
                        </SubmitButton>
                        <ListGroup>
                            {questions?.map((question) =>
                                <ListGroup.Item>
                                    <ItemRow>
                                        {question?.description}
                                        <div>
                                            <LinkText onClick={() => setSelectedQuestion(question)}>Opções de resposta</LinkText>
                                            <RedText onClick={() => handleDeleteQuestion(question)}>Remover</RedText>
                                        </div>
                                    </ItemRow>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Form>
                </ParticipantAddForm>
            </WhiteBackground>
        </Background>
    );
}
