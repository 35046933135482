import styled from 'styled-components';

export const ButtonComponent = styled.button`
    border: none;
    padding: 1px 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.steelblue};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.sm};

    cursor: pointer;
`;
