import { Background } from '@/Components/Background';
import ModalLoading from '@/Components/ModalLoading';
import React, { useEffect, useState } from 'react'
import { GetAttractionsContainer, Grow, MenuRow, SmallerColumn, WhiteBoxContainer, StatusButton } from './styled';
import { Header } from '@/Components/Header';
import { FormButton } from '@/Components/FormButton';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownButton, Form, Table } from 'react-bootstrap';
import { Attraction } from '@/Services/api/models/attractionsModels';
import ApiService from '@/Services/api/ApiService';
import { UsersResponse } from '@/Services/api/models/usersModel';
import { toast } from 'react-toastify';
import { Group, Room, RoomParticipant, RoomParticipantCheckIn } from '@/Services/api/models/ControleDeAcessoModels';
import ControleDeAcessoService, { websocketURL } from '@/Services/controle-de-acesso/ControleDeAcessoService';
import { SignInResponse } from '@/Services/api/models/signInModel';
import { GetParticipantResponse } from '@/Services/api/models/participantsModel';
import { StyledDropdownToggle } from '@/Pages/Participants/Consultar/styled';
import { BlueCheckInArrow, CheckinRow, FilterContainer, FilterFields, RedCheckInArrow } from '../Consultar/styled';
import { formatDjangoDate } from '@/util/FormatDate';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

export default function AccessControlParticipants() {
    const navigate = useNavigate()
    const { eventID } = useParams()
    const [eventId, setEventId] = useState<string>()
    const [userInfo, setUserInfo] = useState<SignInResponse>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [rooms, setRooms] = useState<Room[]>()
    const [groups, setGroups] = useState<Group[]>()
    const [participants, setParticipants] = useState<GetParticipantResponse[]>()
    const [roomParticipantList, setRoomParticipantList] = useState<RoomParticipant[]>()
    const [participantFilter, setParticipantFilter] = useState<string>()
    const [filteredParticipantList, setFilteredParticipantList] = useState<GetParticipantResponse[]>()
    const [checkIn, setCheckIn] = useState<RoomParticipantCheckIn[]>()
    const [groupFilter, setGroupFilter] = useState<{group: number, filter: string} | undefined>(undefined)


    const getEventId = async () => {
        const userInfo = await JSON.parse(localStorage.getItem('token'))
        setUserInfo(userInfo)
        if (eventID) {
            setEventId(eventID)
        }
        else {
            const eventId = userInfo?.events?.[0]
            setEventId(eventId)
        }
    }

    const handleGetParticipants = async () => {
        setIsLoading(true)
        try {
          const data = await ApiService.getAllParticipantsByEvent(Number(eventID))
          setParticipants(data)
          setFilteredParticipantList(data)
        }
        catch (e: any) {
          toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const getEventRooms = async () => {
        setIsLoading(true)
        try{
            const data = await ControleDeAcessoService.getRoomsByEvent(Number(eventId))
            setRooms(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const getEventGroups = async () => {
        setIsLoading(true)
        try{
            const data = await ControleDeAcessoService.getGroupsByEvent(Number(eventId))
            setGroups(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const getCheckIn = async () => {
        setIsLoading(true)
        try {
            const data = await ControleDeAcessoService.getCheckInsByEvent(Number(eventID))
            setCheckIn(data)
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(eventId) {
            getEventRooms()
            getEventGroups()
            handleGetParticipants()
            getCheckIn()
        }
    },[eventId])

    useEffect(() => {
        getEventId()
    },[])

   useEffect(() => {
    if(eventId){
        const ws = new WebSocket(`${websocketURL}/event/?event=${eventId}&checkIns=true`)
        ws.onmessage = (e) => {
            const data = JSON.parse(e.data)
            if(Object.keys(data)?.includes('check-ins')) {
                setCheckIn(check_ins => [...check_ins, ...data['check-ins']])
                console.log(data['check-ins'])
            }
        }
        return () => {
            ws.close()
        }
    }
   },[eventId])

   useEffect(() => {
        if (participants && roomParticipantList) {
            const filtered = participants.filter(participant => {
                if (participantFilter && !(`${participant.name} ${participant.lastName}`).toLowerCase().includes(participantFilter.toLowerCase())) {
                    return false;
                }

                if (groupFilter) {
                    const roomParticipant = roomParticipantList.find(
                        rp => rp.rentpass_participant === participant.id && rp.group === groupFilter.group
                    );
                    if (!roomParticipant || roomParticipant.room.toString() !== groupFilter.filter) {
                        return false;
                    }
                }

                return true;
            });

            setFilteredParticipantList(filtered);
        }
    }, [isLoading, participantFilter, participants, groupFilter, roomParticipantList]);

    
  return (
    <Background>
            <ModalLoading isActive={isLoading} />
            <GetAttractionsContainer>
                <Header pageTitle='Controle de acesso' />
                <WhiteBoxContainer>
                    <MenuRow>
                        <FilterContainer>
                            <FilterFields>
                                <Form.Control
                                    type='text'
                                    placeholder='Filtrar participantes'
                                    onChange={(e) => {
                                        setParticipantFilter(e.target.value);
                                    }}
                                />
                            </FilterFields>
                        </FilterContainer>
                    </MenuRow>
                    <div className='body'></div>
                    <div className='tabela'>
                        {rooms && (
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Sobrenome</th>
                                        <th>Documento</th>
                                        <th>Celular</th>
                                        <th>Categoria</th>
                                        <th>Check-in no Evento</th>
                                        {groups?.map((group) => <>
                                            <th>
                                                <Dropdown>
                                                    <StyledDropdownToggle
                                                        variant='secondary'
                                                    >
                                                        {group.group_name}
                                                    </StyledDropdownToggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            key={'todos'}
                                                            onClick={() => {
                                                                setGroupFilter(undefined)
                                                            }}
                                                        >
                                                            Todos
                                                        </Dropdown.Item>
                                                        {rooms?.filter(room => room.group === group.id)?.map((room) => 
                                                            <Dropdown.Item
                                                                key={room?.id}
                                                                onClick={() => {
                                                                    setGroupFilter({group: group.id, filter: room?.id?.toString()})
                                                                }}
                                                            >
                                                                {' '}
                                                                {room?.room_name}{' '}
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </th>
                                            <th>
                                                Check-in
                                            </th>
                                        </>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredParticipantList?.map((participant) => 
                                        <tr>
                                            <td>
                                                {participant.name}
                                            </td>
                                            <td>
                                                {participant.lastName}
                                            </td>
                                            <td>
                                                {participant.documentNumber}
                                            </td>
                                            <td>
                                                {participant.cellPhoneNumber}
                                            </td>
                                            <td>
                                                {participant.additionalCategory}
                                            </td>
                                            <td>
                                                {formatDjangoDate(participant.checkinDate)}
                                            </td>
                                            {groups?.map((group) => <>
                                                <td>
                                                    {rooms?.find(room => room.id === roomParticipantList?.find((p) => p.rentpass_participant === participant.id && p.group === group.id)?.room)?.room_name}
                                                </td>
                                                <td>
                                                    {checkIn?.filter((checkin) => Number(checkin.participant?.rentpass_participant) === Number(participant.id) && rooms?.find(room => room.id === checkin.room)?.group === group.id)?.map((checkin, index) =>
                                                        <CheckinRow>
                                                            {formatDjangoDate(checkin.timestamp)}
                                                            {(index+1)%2 === 0? <RedCheckInArrow><ArrowDownward /></RedCheckInArrow> : <BlueCheckInArrow><ArrowUpward /></BlueCheckInArrow>}
                                                        </CheckinRow>
                                                    )}
                                                </td>
                                            </>)}
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </WhiteBoxContainer>
            </GetAttractionsContainer>
        </Background>
  )
}
