import styled from 'styled-components';

interface ModalContainerProps {
    width?: string;
    height?: string;
}

export const ModalBlock = styled.div`
    align-items: center;
    bottom: 0;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding: 0.4rem;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    opacity: 1;
    z-index: 400;
`;

export const ModalOverlay = styled.a`
    background: rgba(0, 0, 0, 0.25);
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.4;
`;

export const ModalClose = styled.a`
    float: right !important;
    text-decoration: none !important;
    cursor: pointer;
    font-size: 1rem;
`;

export const ModalContainer = styled.div<ModalContainerProps>`
    background: #ffffff;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    min-height: ${({ height }) => (height ? height : '75vh')};
    max-width: 100%;
    padding: 0 0.8rem;
    width: ${({ width }) => (width ? width : '100%')};
    animation: slide-down 0.2s ease 1;
    z-index: 1;
    justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 15px;
`;

export const ModalBody = styled.div`
    overflow-y: auto;
    padding: 30px 10px;
    position: relative;
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #303742;
    padding: 20px 5px 10px 5px;
`;

export const ModalTitle = styled.span`
    font-size: 30px;
    font-weight: 500;
`;

export const ModalFooter = styled.div`
    padding: 10px 0px;
    text-align: right;
`;

export const Button = styled.button`
    background: #7b2cbf;
    color: white;
    font-size: 1em;
    margin: 10px;
    padding: 5px 10px;
    border: 2px solid #7b2cbf;
    border-radius: 3px;
    cursor: pointer;
`;
