import { Background } from '@/Components/Background'
import { Header } from '@/Components/Header'
import React, { useEffect, useState } from 'react'
import { AddContainer, AttractionTitle, CekDashContainer, Chart, ChartsContainer, DynamicMenuContainer, FilterContainer, FilterFields, Grow, LeftSideContainer, MetricsContainer, MetricsTitle, RightSideContainer, Title, WhiteBoxContainer } from './styled'
import { Form, Table, Tooltip } from 'react-bootstrap'
import { TButton } from '@/Components/TButton'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import { useForm } from 'react-hook-form'
import { FormButton } from '@/Components/FormButton'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService from '@/Services/api/ApiService'
import { Attraction, AttractionParticipantCheckIn, AttractionSummary, ExportAttractionData } from '@/Services/api/models/attractionsModels'
import ModalLoading from '@/Components/ModalLoading'
import { toast } from 'react-toastify'
import { GetParticipantResponse } from '@/Services/api/models/participantsModel'
import { FormatCESSDate, formatDjangoDate } from '@/util/FormatDate'
import { ExportAttractionToExcel } from '@/util/SaveFromExcel'


export default function ControleDeAtracao() {
    const navigate = useNavigate()
    const { eventID, attractionID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [participantFilter, setParticipantFilter] = useState<string>()
    const [attraction, setAttraction] = useState<Attraction>()
    const [attractionParticipantList, setAttractionParticipantList] = useState<AttractionParticipantCheckIn[]>()
    const [filteredAttractionParticipantList, setFilteredAttractionParticipantList] = useState<AttractionParticipantCheckIn[]>()
    const [attractionSummary, setAttractionSummary] = useState<AttractionSummary>()
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false)
    const [userId, setUserId] = useState<number>()
    const [participants, setParticipants] = useState<GetParticipantResponse[]>()

    const chartData = [
        {name: 'Visitantes', value: attractionSummary?.attractionParticipantsCheckIn},
        {name: 'Check-ins no evento', value: attractionSummary?.eventParticipantsCheckIn - attractionSummary?.attractionParticipantsCheckIn},
    ]


    const COLORS = [
        '#3366cc',
        '#dc3912',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
        '#316395',
        '#3366cc',
        '#994499',
        '#22aa99',
        '#aaaa11',
        '#6633cc',
        '#e67300',
        '#8b0707',
        '#651067',
        '#329262',
        '#5574a6',
        '#3b3eac',
        '#b77322',
        '#16d620',
        '#b91383',
        '#f4359e',
        '#9c5935',
        '#a9c413',
        '#2a778d',
        '#668d1c',
        '#bea413',
        '#0c5922',
        '#743411',
    ];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill='white'
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline='central'
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const handleGetParticipants = async () => {
        setIsLoading(true)
        try {
          const data = await ApiService.getAllParticipantsByEvent(Number(eventID))
          setParticipants(data)
        }
        catch (e: any) {
          toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
      }

    const handleGetUserInfo = async () => {
        setIsLoading(true)
        try{
            const userInfo = localStorage.getItem('token')
            const jsonUserInfo = JSON.parse(userInfo)
            if(jsonUserInfo?.user?.userGroup === 'ADMIN'){
                setIsUserAdmin(true)
            }
            else {
                if(jsonUserInfo?.user?.userGroup === 'ATTRACTION') {
                    setUserId(Number(jsonUserInfo?.user?.id))
                }
                else {
                    navigate(-1)
                }
            }
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleGetAttraction = async () => {
        setIsLoading(true)
        try {
            const data = await ApiService.attractionFindById(Number(attractionID))
            setAttraction(data)
            if (!isUserAdmin) {
                if (userId) {
                    if(userId !== data.userId) {
                        navigate(-1)
                    }
                }
            }
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleGetAttractionParticipantList = async () => {
        setIsLoading(true)
        try{
            if(attraction) {
                const data = await ApiService.attractionParticipantList(attraction?.id)
                setAttractionParticipantList(data)
                setFilteredAttractionParticipantList(data)
            }
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }


    const handleGetAttractionSummary = async () => {
        setIsLoading(true)
        try{
            if(attraction) {
                const data = await ApiService.attractionSummary(attraction?.id)
                setAttractionSummary(data)
                
            }
        }
        catch (e: any) {
            toast.error(e?.response?.data?.message ?? 'Erro desconhecido')
        }
        finally{
            setIsLoading(false)
        }
    }

    const handleFilterParticipant = () => {
        if (participantFilter === '' || !participantFilter) {
            setFilteredAttractionParticipantList(attractionParticipantList)
        }
        else {
            const filteredParticipants = attractionParticipantList.filter((pt) => {
                const participant = participants.find(p => p.id === pt.participantId);
                return participant && (
                    pt.participantId.toString() === participantFilter ||
                    (participant.name.toLowerCase() + participant.lastName.toLowerCase()).includes(participantFilter.toLowerCase().replaceAll(' ', '')) ||
                    participant.documentNumber.includes(participantFilter)
                );
            });
            setFilteredAttractionParticipantList(filteredParticipants)
        }
    }

    const handleExportToExcel = async () => {
        const data:ExportAttractionData[] = attractionParticipantList?.map((participant) => {
            const p = participants?.find((pt) => pt.id === participant.participantId)
            if (p) {
                return {
                    id: p.id,
                    name:p.name,
                    lastName: p.lastName,
                    telCelular: p.cellPhoneNumber,
                    email: p.email,
                    checkInDate: p.checkinDate,
                    CheckIn: participant.insertDatetime,
                    result: participant?.result ?? ''
                }
            }
            return null;
        }).filter(Boolean);
        const response = ExportAttractionToExcel(attraction?.name, data)
    }

    useEffect(() => {
        handleGetUserInfo()
        handleGetParticipants()
        
    },[])

    useEffect(() => {
        handleGetAttraction()
    },[userId, isUserAdmin])

    useEffect(() => {
        handleGetAttractionParticipantList()
        handleGetAttractionSummary()
    },[attraction])

    useEffect(() => {
        handleFilterParticipant()
    },[participantFilter])

  return (
    <Background>
        <ModalLoading isActive={isLoading} />
        <CekDashContainer>
            <Header pageTitle='Capture Control' />
            <AttractionTitle>
                <span>Atração: </span>
                {attraction?.name}
            </AttractionTitle>
            <WhiteBoxContainer>
                <LeftSideContainer>
                    <AddContainer>
                        <Grow>
                            <FormButton onClick={handleExportToExcel}>
                                Exportar Excel
                            </FormButton>
                        </Grow>
                        <Grow>
                            <FormButton onClick={() => navigate(`/modulos/cess/check-in/${eventID}/${attractionID}`)}>
                                Check-in/Check-out
                            </FormButton>
                        </Grow>
                    </AddContainer>
                    <DynamicMenuContainer>
                        <FilterFields>
                            <Form.Control
                                type='text'
                                autoFocus
                                placeholder='Filtrar participante por Nome, Documento ou ID'
                                onChange={(e) => setParticipantFilter(e.target.value)}
                                className='addField'
                            />
                        </FilterFields>
                    </DynamicMenuContainer>
                    <div className='tabela'>
                        <Table hover id='participantsTable'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nome</th>
                                    <th>Sobrenome</th>
                                    <th>Check-in</th>
                                    {filteredAttractionParticipantList?.filter((p) => p?.result)?.length > 0 &&
                                        <th>Resultado</th>
                                    }
                                </tr>
                                </thead> 
                                <tbody>
                                    {filteredAttractionParticipantList?.map((participant) => 
                                        <tr>
                                            <td></td>
                                            <td>{participants?.find((p => p.id === participant.participantId))?.name}</td>
                                            <td>{participants?.find((p => p.id === participant.participantId))?.lastName}</td>
                                            <td>{FormatCESSDate(participant.insertDatetime)}</td>
                                            {filteredAttractionParticipantList?.filter((p) => p?.result)?.length > 0 &&
                                                <td>{participant?.result}</td>
                                            }
                                        </tr>
                                    )}
                                </tbody> 
                        </Table>
                    </div>
                </LeftSideContainer>
                <RightSideContainer>
                    <ChartsContainer>
                        <Chart>
                            <Title> Participantes </Title>
                            <PieChart width={300} height={300}>
                                <Pie
                                    data={chartData}
                                    cx='50%'
                                    cy='50%'
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill='#8884d8'
                                    dataKey='value'
                                >
                                    {chartData.map((_, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend layout='vertical' verticalAlign='bottom' />
                            </PieChart>
                        </Chart>
                        <MetricsContainer>
                            <MetricsTitle> Total de Visitas: {attractionSummary?.attractionCheckInTotal} </MetricsTitle>
                            <MetricsTitle> Total de Visitantes Únicos: {attractionSummary?.attractionParticipantsCheckIn} </MetricsTitle>
                            <MetricsTitle> Total de Check-ins no evento: {attractionSummary?.eventParticipantsCheckIn} </MetricsTitle>
                        </MetricsContainer>
                    </ChartsContainer>
                </RightSideContainer>
            </WhiteBoxContainer>
        </CekDashContainer>
    </Background>
  )
}
