import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import styled from 'styled-components';

export const BoxesContainer = styled.div`
    gap: 50px;
    text-align: left;
`;

export const Box = styled(WhiteBackground)`
    align-items: flex-start;
    text-align: left;

    padding: 20px;
    gap: 10px;
`;

export const Title = styled.h4`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: bold;
    margin: 0;
`;

export const Step = styled.p`
    color: ${({ theme }) => theme.colors['gray-700']};
    font-size: ${({ theme }) => theme.fontSize.base};
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const ButtonContainer = styled.div`
    padding-left: 1%
`

export const WarnText = styled.h4`
    color: ${({ theme }) => theme.colors['red-500']};
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: bold;
    margin: 0;
`;


export const Column = styled.td`
   width: 90vw 
`;

export const SmallerColumn = styled.td`
    .red {
        background-color: ${({ theme }) => theme.colors['pink-500']};
    }
`

export const StatusButton = styled.button`
    font-size: ${({ theme }) => theme.fontSize.us};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    text-transform: uppercase;
    border-radius: 4px;

    border: none;
    padding: 3px 8px;
`;
