import { Background } from '@/Components/Background';
import { Header } from '@/Components/Header';
import { TButton } from '@/Components/TButton';
import ApiService from '@/Services/api/ApiService';
import { CreateUserRequest, IUserGroup, UsersResponse } from '@/Services/api/models/usersModel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Col, Form, ListGroup, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashedHR, EventAddForm, SubmitButton, WarnText } from './styled';

import Select from 'react-select';
import * as yup from 'yup';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ModalLoading from '@/Components/ModalLoading';

import { toast } from 'react-toastify';
import { EventResponse } from '@/Services/api/models/eventModel';
import { formatDateToString } from '@/util/FormatDate';
import { WhiteBackground } from '@/Components/WhiteBackground/styled';
import RsvpService from '@/Services/rsvp/RsvpService';

interface UserFormValues {
    name: string;
    email: string;
    password: string;
    events: { label: string; value: number }[];
    userGroup: {
        label: string;
        value: keyof typeof IUserGroup;
    };
    active: boolean;
    additionalCategory: string;
    additionalCategories: string[];
    tablet: boolean;
}

export function AddUser() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isRecipient, setIsRecipient] = useState<boolean>(false)
    const [idPg, setIdPg] = useState<string>()

    const schema = yup
        .object({
            name: yup.string().required('O campo Nome é obrigatório'),
            email: yup.string().required('O campo Email é obrigatório'),
            password: yup.string(),
            events: yup.array().of(yup.object().shape({
                label: yup.string(),
                value: yup.number().optional(),
            })),
            userGroup: yup.object().shape({
                label: yup.string().required('status is required (from label)'),
                value: yup.mixed<IUserGroup>().required('O campo Grupo de usuário é obrigatório'),
            }),
            active: yup.boolean(),
            tablet: yup.boolean()
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues,
        watch,
    } = useForm<UserFormValues>({
        resolver: yupResolver(schema),
    });

    const [userToEdit, setUserToEdit] = useState<UsersResponse>();
    const [availableEvents, setAvailableEvents] = useState<EventResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [canViculateToEvent, setCanVinculateToEvent] = useState<boolean>(false);

    const handleGetUserByID = async (id: number) => {
        try {
            setIsLoading(true);
            const response = await ApiService.getUserByID(id);
            setUserToEdit(response);
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetEvents = async () => {
        try {
            setIsLoading(true);
            const response = await ApiService.getEventosBySpecification(
                location?.state?.event? {id: location?.state?.event} : {active: true}
            );
            setAvailableEvents(response);
        } catch (e) {
            toast.error('Houve um erro ao trazer os eventos disponíveis');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetEvents();
    }, []);

    useEffect(() => {
        if (getValues('userGroup.value') === 'ADMIN') {
            setCanVinculateToEvent(false);
        } else {
            setCanVinculateToEvent(true);
        }
    }, [watch('userGroup')]);

    useEffect(() => {
        if (location?.state?.userId) {
            handleGetUserByID(+location.state.userId);
        }
    }, [location.state]);

    const handleFullFillEvent = async (
        events: number[],
    ) => {
        try {
            setIsLoading(true);
            const eventsInfo = await Promise.all(events.map(async (event) => {
                const response =  await ApiService.getEventById(event)
                return {label: response.description, value: response.id}
            }))
            setValue('events',eventsInfo)
        } catch (e) {
            toast.error('Erro ao carregar evento vinculado ao usuário');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (userToEdit) {
            setValue('name', userToEdit.name);
            setValue('email', userToEdit.email);
            setValue('userGroup', {
                label: IUserGroup[userToEdit.userGroup].toLowerCase(),
                value: userToEdit.userGroup,
            });
            setValue('active', !userToEdit.active);
            if (userToEdit.userGroup !== 'ADMIN') {
                handleFullFillEvent(userToEdit.events)
            }
            setValue('additionalCategory', userToEdit?.additionalCategory)
        }
    }, [userToEdit]);

    const handleSubmitForm: SubmitHandler<UserFormValues> = async (data) => {
        try {
            setIsLoading(true);
            const createUserRequest: CreateUserRequest = {
                active: !data.active,
                name: data.name,
                userGroup: data.userGroup.value,
                events: [],
                email: data.email,
                password: data.password,
                additionalCategory: data.additionalCategories?.length > 0? data?.additionalCategories?.toString() : null,
                tablet: data.tablet ?? false
            };

            if (data.userGroup.value !== 'ADMIN') {
                createUserRequest.events = data.events.map((evento) => evento.value);
            }

            if (!!userToEdit?.id) {
                createUserRequest.id = userToEdit.id;

                if (!!data.password) {
                    await ApiService.UpdateUserPassword({ ...userToEdit, password: data.password });
                }
            }

            await ApiService.upInsertUser(createUserRequest).then(response => console.log(response))
            
            if(isRecipient && idPg) {
                await RsvpService.createRecipient({
                    token: null,
                    user_id: null,
                    rentpass_event: data?.events?.[0].value,
                    id_pg: idPg
                })
            } 

            if(location?.state?.event) {
                navigate('/usuarios/consultar',{state: {event: location?.state?.event}});
            }
            else {
                navigate('/usuarios/consultar')
            }
        } catch (e: any) {
            toast.error(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEventSelect = (selectedOption: { label: string; value: number } | null) => {
        const currentEvents = getValues('events') || [];
        if (selectedOption && currentEvents.filter(evento => evento.value === selectedOption.value).length === 0) {
            currentEvents.push(selectedOption);
        }
        else {
            currentEvents.splice(currentEvents.indexOf(selectedOption),1)
        }
        setValue('events', currentEvents);
    };

    useEffect(() => {
        if(watch('additionalCategory') && watch('additionalCategory') !== '') {
            if(watch('additionalCategories')) {
                const newCategories = watch('additionalCategories')
                newCategories.push(watch('additionalCategory'))
                setValue('additionalCategories', newCategories)
            } else {
                setValue('additionalCategories', [watch('additionalCategory')])
            }
        }
    },[watch('additionalCategory')])

    const handleRemoveCategory = (item: string) => {
        const categories = watch('additionalCategories')
        const newCategories = categories.filter(c => c !== item)
        setValue('additionalCategories', newCategories)
    }

    return (
        <Background>
            <ModalLoading isActive={isLoading} />
            <Header pageTitle='Edição de Usuário' />
            <WhiteBackground>
                <EventAddForm>
                    <Form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Nome
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control type='text' {...register('name')} />
                                {errors.name?.message && (
                                    <WarnText> {errors.name.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Usuário
                            </Form.Label>
                            <Col sm={10}>
                                {/* // TODO:: melhorar isso */}
                                <Form.Control {...register('email')} type='text' />
                                {errors.email?.message && (
                                    <WarnText> {errors.email.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                {userToEdit ? '*Nova Senha' : '*Senha'}
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control {...register('password')} type='password' />
                                {errors.password?.message && (
                                    <WarnText> {errors.password.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>

                        <DashedHR />
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Grupos
                            </Form.Label>
                            <Col sm={10}>
                                <Controller
                                    name={'userGroup'}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            className='groupSelect'
                                            {...field}
                                            options={(
                                                Object.keys(
                                                    IUserGroup,
                                                ) as (keyof typeof IUserGroup)[]
                                            ).filter((group) => location?.state?.event? (group !== "ADMIN" && group !== "PARTNER_ADMIN") : (group === "ADMIN" || group === 'PARTNER_ADMIN')).map((groupMapped) => {
                                                return {
                                                    label: IUserGroup[groupMapped].toLowerCase(),
                                                    value: groupMapped,
                                                };
                                            })}
                                            styles={{
                                                placeholder: (styles) => {
                                                    return {
                                                        ...styles,
                                                    };
                                                },
                                                control: (styles) => {
                                                    return {
                                                        ...styles,
                                                        ':hover': {},
                                                        ':focus-within': {
                                                            border: '1px solid black',
                                                        },
                                                    };
                                                },
                                                container: (styles) => {
                                                    return {
                                                        ...styles,
                                                        textTransform: 'capitalize',
                                                    };
                                                },
                                            }}
                                        />
                                    )}
                                />
                                {errors.userGroup?.value && (
                                    <WarnText>
                                        {' '}
                                        <> {errors.userGroup.value.message} </>{' '}
                                    </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <DashedHR />
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm={2}>
                                *Evento Vinculado
                            </Form.Label>
                            <Col sm={10}>
                                <Controller
                                    name={'events'}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            className='groupSelect'
                                            {...field}
                                            options={availableEvents.map((evento) => ({
                                                label: evento.description,
                                                value: evento.id
                                            })) as { label: string; value: number }[]}
                                            isDisabled={!canViculateToEvent}
                                            onChange={handleEventSelect}
                                            styles={{
                                                placeholder: (styles) => {
                                                    return {
                                                        ...styles,
                                                    };
                                                },
                                                control: (styles) => {
                                                    return {
                                                        ...styles,
                                                        ':hover': {},
                                                        ':focus-within': {
                                                            border: '1px solid black',
                                                        },
                                                    };
                                                },
                                                container: (styles) => {
                                                    return {
                                                        ...styles,
                                                        textTransform: 'capitalize',
                                                    };
                                                },
                                            }}
                                        />
                                    )}
                                />
                                {errors.userGroup?.value && (
                                    <WarnText>
                                        {' '}
                                        <> {errors.userGroup.value.message} </>{' '}
                                    </WarnText>
                                )}
                            </Col>
                            <ListGroup>
                                {watch('events',[])?.map(evento => 
                                    <ListGroup.Item onClick={() => handleEventSelect(evento)}>{evento.label}</ListGroup.Item>
                                )}
                            </ListGroup>
                        </Form.Group>
                        {(watch('userGroup.value') === 'SELF_SERVICE' && availableEvents?.find(event =>  event.id === watch('events')?.[0]?.value)?.additionalCategories) && 
                            <>
                                <DashedHR />
                                <Form.Group as={Row} className='mb-3'>
                                    <Form.Label column sm={2}>
                                        Categorias de Participantes
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Select
                                            aria-label='Default select example'
                                            {...register('additionalCategory')}
                                        >
                                            <option value={''}> -- Selecione -- </option>
                                            {availableEvents?.find(event =>  event.id === watch('events')?.[0]?.value)?.additionalCategories?.split(',')?.map((item) => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Select>

                                        {errors.additionalCategory && (
                                            <WarnText>
                                                {' '}
                                                <> {errors.additionalCategory.message} </>{' '}
                                            </WarnText>
                                        )}
                                    </Col>
                                </Form.Group>
                                <ListGroup>
                                    {watch('additionalCategories',[])?.map(item => 
                                        <ListGroup.Item onClick={() => handleRemoveCategory(item)}>{item}</ListGroup.Item>
                                    )}
                                </ListGroup>
                                <Form.Group as={Row} className='mb-3'>
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <Form.Check
                                            {...register('tablet')}
                                            type={'checkbox'}
                                            id={`tablet`}
                                            label={`Modo tablet`}
                                        />
                                        {errors.active?.message && (
                                            <WarnText> {errors.tablet.message} </WarnText>
                                        )}
                                    </Col>
                                </Form.Group>
                            </>
                        }
                        <DashedHR />
                        {watch('userGroup.value') === 'CLIENT' && <>
                            <Form.Group as={Row} className='mb-3'>
                                <Col sm={{ span: 10, offset: 2 }}>
                                    <Form.Check
                                        onChange={(v) => setIsRecipient(v?.target?.checked)}
                                        type={'checkbox'}
                                        id={`Inativo-${'checkbox'}`}
                                        label={`Cadastrar como recebedor`}
                                    />
                                    {errors.active?.message && (
                                        <WarnText> {errors.active.message} </WarnText>
                                    )}
                                </Col>
                            </Form.Group>
                            {isRecipient &&
                                <Form.Group as={Row} className='mb-3'>
                                    <Form.Label column sm={2}>
                                        ID Pagar.me
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control onChange={(e) => setIdPg(e.target.value)} type='text' />
                                    </Col>
                                </Form.Group>
                            }
                            <DashedHR />
                        </>}
                        <Form.Group as={Row} className='mb-3'>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Check
                                    {...register('active')}
                                    type={'checkbox'}
                                    id={`Inativo-${'checkbox'}`}
                                    label={`Inativo`}
                                />
                                {errors.active?.message && (
                                    <WarnText> {errors.active.message} </WarnText>
                                )}
                            </Col>
                        </Form.Group>
                        <SubmitButton>
                            <TButton> Gravar </TButton>
                        </SubmitButton>
                    </Form>
                </EventAddForm>
            </WhiteBackground>
        </Background>
    );
}
